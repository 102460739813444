/* eslint-disable @typescript-eslint/no-explicit-any */
import CommonTurtlesBreedingService from '$shared/services/turtles-breeding';

import { DUXPLORER_URL } from '$/constants';

import helperService from '../helper';
import assetsService from '../assets';
import transactionService from '../transaction';

class FrontendTurtlesBreedingService extends CommonTurtlesBreedingService {
    DUXPLORER_URL = DUXPLORER_URL;

    helperService = helperService;

    transactionService = transactionService;

    assetsService = assetsService;
}

export default FrontendTurtlesBreedingService;
