import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IArtefact } from '$shared/types';

import useUsableItemHandler from '$/hooks/items/useUsableItemHandler';

import AbstractArtefact from './AbstractArtefact';

interface Props {
    artefact: IArtefact;
    onUse?: (artefact: IArtefact) => Promise<void>;
    num?: number;
}

const UsableOnAccountArtefact = ({ artefact, onUse, num }: Props): JSX.Element => {
    const { t } = useTranslation();
    const use = useUsableItemHandler(artefact, { onUse });

    return <AbstractArtefact num={num} artefact={artefact} footerButtonText={t('artefact.use')} onClick={use} />;
};

export default UsableOnAccountArtefact;
