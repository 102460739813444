import { getAddress } from '../../domain/constants';
import CommonAnimalsIncubatorService from '../animals-incubator/CommonAnimalsIncubatorService';

const INCUBATOR_DAPP_ADDRESS = getAddress('INCUBATOR_DAPP');

abstract class CommonIncubatorService extends CommonAnimalsIncubatorService {
    protected DAPP_ADDRESS = INCUBATOR_DAPP_ADDRESS;
    ANIMAL_KEY = 'duck';
}

export default CommonIncubatorService;
