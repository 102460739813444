import axios, { AxiosInstance } from 'axios';

import CommonHelperService from '$shared/services/helper';

import { API_URL, BLOCKCHAIN_URL, DOMAIN_HOST, NODE_URL, SCAN_URL } from '$/constants';
import { getDeviceId } from '$/browser/localStorage';

import packageJson from '../../../../package.json';

class FrontendHelperService extends CommonHelperService {
    http: AxiosInstance;

    API_URL = API_URL;

    BACKEND_URL = BLOCKCHAIN_URL;

    NODE_URL = NODE_URL;

    SCAN_URL = SCAN_URL;

    constructor() {
        super();

        this.http = axios.create({ baseURL: API_URL });
        this.http.interceptors.request.use((configs) => {
            if (!configs.url?.includes(DOMAIN_HOST)) {
                return configs;
            }

            return {
                ...configs,
                headers: {
                    ...configs.headers,
                    'device-id': getDeviceId(),
                    'platform': 'browser',
                    'app-version': packageJson.version,
                },
            };
        });
    }
}

export default FrontendHelperService;
