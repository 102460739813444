/* eslint-disable @typescript-eslint/no-explicit-any */
import CommonCaninesService from '$shared/services/canines';
import { isAddress } from '$shared/domain/guards';
import { IAnimalsDetails } from '$shared/types/animals';
import { urlString } from '$shared/utils';
import { getAddress } from '$shared/domain/constants';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import assetsService from '../assets';
import auctionService from '../auctions';
import caninesBreedingService from '../canines-breeding';
import caninesIncubatorService from '../canines-incubator';
import helperService from '../helper';
import productionService from '../production';
import scanService from '../scan';
import caninesFarmingService from '../canines-farming/instance';

class FrontendCaninesService extends CommonCaninesService {
    breedingService = caninesBreedingService;

    farmingService = caninesFarmingService;

    incubatorService = caninesIncubatorService;

    helperService = helperService;

    assetsService = assetsService;

    productionService = productionService;

    auctionService = auctionService;

    scanService = scanService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    APP_URL = APP_URL;

    DAPPS_CONTRACTS = {
        farming: getAddress('CANINES_FARMING_DAPP'),
        auction: getAddress('AUCTION_DAPP'),
    };

    fetchUserPageAnimal = async (address: addressId): Promise<IAnimalsDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<IAnimalsDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/canines`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };

    fetchOneAnimalDetails = async (nftId: string): Promise<IAnimalsDetails> => {
        const [canines] = await Promise.all([this.getOneAnimalDetails(nftId)]);
        return canines;
    };

    fetchUserPageCanineV2 = async (address: addressId): Promise<any> => {
        const allCaninesFromUser: Array<{ [key: string]: any }> = [];
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get(
            urlString(`${API_URL}/v2/addresses/${address}/canines/locked`),
            {
                params: { size: 200_000 },
            },
        );

        const { data: result } = data;
        result.data.forEach((canine: IAnimalsDetails) => {
            const canineDetails = {
                ...canine,
                issueNumber: canine.issueNumber,

                onFarming: !canine?.locks
                    ? false
                    : canine.locks?.some(
                          (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                      ),
                onSale: canine.locks?.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
            };

            allCaninesFromUser.push(canineDetails);
        });

        return allCaninesFromUser;
    };
}

export default FrontendCaninesService;
