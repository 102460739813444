import CommonTurtlesService from '$shared/services/turtles';
import { ITurtlesDetails } from '$shared/types';
import { urlString } from '$shared/utils';
import { isAddress } from '$shared/domain/guards';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import helperService from '../helper';
import assetsService from '../assets';
import turtlesIncubatorService from '../turtles-incubator';
import productionService from '../production';
import auctionService from '../auctions';
import turtlesBreedingService from '../turtles-breeding';
import turtleFarmingService from '../turtles-farming';
import scanService from '../scan';

class FrontendTurtleService extends CommonTurtlesService {
    breedingService = turtlesBreedingService;

    farmingService = turtleFarmingService;

    incubatorService = turtlesIncubatorService;

    scanService = scanService;

    helperService = helperService;

    assetsService = assetsService;

    turtlesIncubatorService = turtlesIncubatorService;

    productionService = productionService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    auctionService = auctionService;

    turtleBreedingService = turtlesBreedingService;

    turtleFarmingService = turtleFarmingService;

    APP_URL = APP_URL;

    fetchOneTurtleDetails = async (nftId: string): Promise<ITurtlesDetails> => {
        const [turtle] = await Promise.all([this.getOneAnimalDetails(nftId)]);
        return turtle;
    };

    fetchUserPageTurtles = async (address: addressId): Promise<ITurtlesDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<ITurtlesDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/turtles`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };
}

export default FrontendTurtleService;
