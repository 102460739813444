import type { WithRarityParams } from '../../types';
import type CommonHelperService from '../helper/CommonHelperService';
import type CommonBreedingService from '../breeding';
import type CommonTurtlesBreedingService from '../turtles-breeding';
import type CommonIncubatorService from '../incubator';
import type CommonTurtlesIncubatorService from '../turtles-incubator';
import type CommonTurtlesFarmingService from '../turtles-farming';

import { isFakeDuck, isJackpot } from '../../domain/ducks';
import { getKeyPart } from '../../domain/contract-data';
import { withCache } from '../../utils';
import CommonFarmingService from '../farming/CommonFarmingService';

import { Canine, Duck, EnrichedDuck, Feline, veggEnrichedCanine, veggEnrichedDuck } from '$shared/types/cache-api';
import CommonVeggFarmingService from '$shared/services/vegg-farming';
import { IDuckDetailsV2 } from '$shared/types/ducks';
import CommonAnimalsBreedingService from '../animals-breeding/CommonAnimalsBreedingService';
import { ANIMAL_PREFIXES } from '$shared/enums';
import CommonCaninesIncubatorService from '$shared/services/canines-incubator/CommonCainnesIncubatorService';
import CommonFelinesIncubatorService from '$shared/services/felines-incubator/CommonFelinesIncubatorService';

import CommonAnimalsIncubatorService from '../animals-incubator/CommonAnimalsIncubatorService';
import { isFakeAnimal } from '$shared/domain/animals';

type GenotypeAmounts = {
    byGenotypeAndGeneration: Record<string, number>;
};

const calculateRarity = <T extends { issuer: addressId; name: string }>(amountOfAnimal: number, nft: T) => {
    if (isFakeAnimal(nft)) {
        return -1; // IT'S FAKE NFT!!!
    } else if (isJackpot(nft)) {
        return 100;
    }
    const amount = amountOfAnimal > 0 ? amountOfAnimal : amountOfAnimal + 1;
    const rarity = (1 / Math.sqrt(amount)) * 100;
    return Number.isNaN(rarity) ? 100 : rarity;
};

abstract class CommonProductionService {
    protected abstract helperService: CommonHelperService;
    protected abstract farmingService: CommonFarmingService;
    protected abstract veggFarmingService: CommonVeggFarmingService;
    protected abstract farmingTurtlesService: CommonTurtlesFarmingService;
    protected abstract breedingService: CommonBreedingService;
    protected abstract breedingTurtlesService: CommonTurtlesBreedingService;
    protected abstract incubatorService: CommonIncubatorService;
    protected abstract incubatorTurtlesService: CommonTurtlesIncubatorService;
    protected abstract breedingCaninesService: CommonAnimalsBreedingService;
    protected abstract breedingFelineService: CommonAnimalsBreedingService;
    protected abstract incubatorFelinesService: CommonFelinesIncubatorService;
    protected abstract incubatorCaninesService: CommonCaninesIncubatorService;

    getGenotypeAmounts = withCache(async (): Promise<GenotypeAmounts> => {
        const [breedingGenotypeAmount, incubatorGenotypeAmount] = await Promise.all([
            this.breedingService.getGenotypeAmounts(),
            this.incubatorService.fetchGenotypeAmounts(),
        ]);

        const byGenotypeAndGeneration: Record<string, number> = {};

        [...breedingGenotypeAmount, ...incubatorGenotypeAmount].forEach(({ key, value }) => {
            const fullGenome = getKeyPart(key, 1);
            const genotypeAndGeneration = this.helperService.getGenotypePattern(fullGenome);

            if (!byGenotypeAndGeneration[genotypeAndGeneration]) {
                byGenotypeAndGeneration[genotypeAndGeneration] = 0;
            }

            byGenotypeAndGeneration[genotypeAndGeneration] += value;
        });

        return {
            byGenotypeAndGeneration,
        };
    }, 30_000);

    getTurtlesGenotypeAmounts = withCache(async (): Promise<GenotypeAmounts> => {
        const [breedingGenotypeAmount, incubatorGenotypeAmount] = await Promise.all([
            this.breedingTurtlesService.getGenotypeAmounts(),
            this.incubatorTurtlesService.fetchGenotypeAmounts(),
        ]);

        const byGenotypeAndGeneration: Record<string, number> = {};

        [...breedingGenotypeAmount, ...incubatorGenotypeAmount].forEach(({ key, value }) => {
            const fullGenome = getKeyPart(key, 1);
            const genotypeAndGeneration = this.helperService.getGenotypePattern(fullGenome);

            if (!byGenotypeAndGeneration[genotypeAndGeneration]) {
                byGenotypeAndGeneration[genotypeAndGeneration] = 0;
            }

            byGenotypeAndGeneration[genotypeAndGeneration] += value;
        });

        return {
            byGenotypeAndGeneration,
        };
    }, 30_000);

    getGenotypeAnimal = withCache(async (animalType: ANIMAL_PREFIXES): Promise<GenotypeAmounts> => {
        if (animalType === ANIMAL_PREFIXES.TURTLE) {
            return this.getGenotypeAnimalAmounts(this.breedingTurtlesService, this.incubatorTurtlesService);
        }
        if (animalType === ANIMAL_PREFIXES.CANI) {
            return this.getGenotypeAnimalAmounts(this.breedingCaninesService, this.incubatorCaninesService);
        }
        if (animalType === ANIMAL_PREFIXES.FELI) {
            return this.getGenotypeAnimalAmounts(this.breedingFelineService, this.incubatorFelinesService);
        }

        return this.getGenotypeAnimalAmounts(this.breedingService, this.incubatorService);
    }, 30_000);

    getGenotypeAnimalAmounts = withCache(
        async (
            breedingService: CommonBreedingService | CommonTurtlesBreedingService | CommonAnimalsBreedingService,
            incubatorService: CommonIncubatorService | CommonTurtlesIncubatorService | CommonAnimalsIncubatorService,
        ): Promise<GenotypeAmounts> => {
            const [breedingGenotypeAmount, incubatorGenotypeAmount] = await Promise.all([
                breedingService.getGenotypeAmounts(),
                incubatorService.fetchGenotypeAmounts(),
            ]);

            const byGenotypeAndGeneration: Record<string, number> = {};

            [...breedingGenotypeAmount, ...incubatorGenotypeAmount].forEach(({ key, value }) => {
                const fullGenome = getKeyPart(key, 1);
                const genotypeAndGeneration = this.helperService.getGenotypePattern(fullGenome);

                if (!byGenotypeAndGeneration[genotypeAndGeneration]) {
                    byGenotypeAndGeneration[genotypeAndGeneration] = 0;
                }

                byGenotypeAndGeneration[genotypeAndGeneration] += value;
            });

            return {
                byGenotypeAndGeneration,
            };
        },
        30_000,
    );

    calcEggProduction = (farmingPower: number, globalFarmingPower: number): string => {
        return (((40 * 30.4) / globalFarmingPower) * farmingPower).toFixed(4);
    };

    getEggProduction = async (farmingPower: number) => {
        const { globalStaked } = await this.farmingService.fetchFarmingGlobals();

        return this.calcEggProduction(farmingPower, globalStaked);
    };

    addVEggProduction = async (ducks: Array<EnrichedDuck>): Promise<Array<veggEnrichedDuck | veggEnrichedCanine>> => {
        const ducksWithEggProduction = ducks.map(async (duck) => {
            //console.log('ADD VEGG DUCK', duck);
            if (duck.veggBasePower) {
                //TODO: Query the vegg farming stats
                const { global, toClaim, globalInterest, assetInterest, claimed } =
                    await this.veggFarmingService.fetchFarmingPower(
                        duck.assetId,
                        duck.name,
                        duck.owner,
                        duck.veggBasePower,
                    );
                return {
                    ...duck,
                    veggFarmingParams: {
                        farmingPower: (duck.veggBasePower / 100) * duck.oldRarity,
                        globalFarmingPower: global,
                        stakedBefore: true,
                        toClaim,
                        globalInterest,
                        assetInterest,
                        claimed,
                    },
                };
            } else {
                return {
                    ...duck,
                    veggFarmingParams: {
                        farmingPower: 0,
                        globalFarmingPower: 0,
                        stakedBefore: false,
                        toClaim: 0,
                        globalInterest: 0,
                        assetInterest: 0,
                        claimed: 0,
                    },
                };
            }
        });
        return Promise.all(ducksWithEggProduction);
    };

    addVEggProductionOne = async <T extends { oldRarity: number; assetId: string; name: string }>(
        duck: IDuckDetailsV2,
    ): Promise<IDuckDetailsV2> => {
        if (duck.veggBasePower) {
            const { global, toClaim, globalInterest, assetInterest, claimed } =
                await this.veggFarmingService.fetchFarmingPower(
                    duck.assetId,
                    duck.name,
                    duck.owner,
                    duck.veggBasePower,
                );
            return {
                ...duck,
                veggFarmingParams: {
                    farmingPower: (duck.veggBasePower / 100) * duck.oldRarity,
                    globalFarmingPower: global,
                    stakedBefore: true,
                    toClaim,
                    globalInterest,
                    assetInterest,
                    claimed,
                },
            };
        } else {
            return {
                ...duck,
                veggFarmingParams: {
                    farmingPower: 0,
                    globalFarmingPower: 0,
                    stakedBefore: false,
                    toClaim: 0,
                    globalInterest: 0,
                    assetInterest: 0,
                    claimed: 0,
                },
            };
        }
    };

    addEggProduction = async <T extends { oldRarity: number; assetId: string; name: string }>(
        ducks: Duck[] | Canine[],
    ): Promise<Array<EnrichedDuck>> => {
        //console.log('EGG PRODUCTION ADD', ducks);
        const ducksWithEggProduction = ducks.map(async (duck) => {
            const { fp, global, toClaim, globalInterest, assetInterest, claimed } =
                await this.farmingService.fetchFarmingPower(duck.assetId, duck.name, duck.veggBasePower);

            const farmingPower = Math.floor((fp / 100) * Math.floor(duck.oldRarity));
            const eggProduction = this.calcEggProduction(farmingPower, global);
            console.log(duck.assetId);
            return {
                ...duck,
                eggProduction,
                farmingParams: {
                    farmingPower,
                    globalFarmingPower: global,
                    stakedBefore: !!duck.basePower,
                    toClaim,
                    globalInterest,
                    assetInterest,
                    claimed,
                },
            };
        });
        return Promise.all(ducksWithEggProduction);
    };

    addAnimalRarity = async <T extends { issuer: addressId; name: string }>(
        nfts: T[],
    ): Promise<Array<T & WithRarityParams>> => {
        const [{ byGenotypeAndGeneration }, { globalFarmingPower }] = await Promise.all([
            this.getTurtlesGenotypeAmounts(),
            this.farmingTurtlesService.fetchFarmingGlobals(),
        ]);

        const mappedPromises = nfts.map(async (nft) => {
            const genotypeTemplate = this.helperService.getGenotypePattern(nft.name);
            const animalsWithSameGenes = byGenotypeAndGeneration[genotypeTemplate];
            const rarity = await this.helperService.getAnimalFarmPower(nft.name);
            const eggProduction = '0';

            return {
                ...nft,
                animalsWithSameGenes,
                rarity,
                globalFarmingPower,
                eggProduction,
            };
        });

        return await Promise.all(mappedPromises);
    };

    addEggProductionOne = async <T extends { oldRarity: number; assetId: string; name: string }>(
        duck: IDuckDetailsV2,
    ): Promise<IDuckDetailsV2> => {
        const { fp, global, toClaim, globalInterest, assetInterest, claimed } =
            await this.farmingService.fetchFarmingPower(duck.assetId, duck.name, duck.veggBasePower);

        const farmingPower = Math.floor((fp / 100) * Math.floor(duck.oldRarity));
        const eggProduction = this.calcEggProduction(farmingPower, global);

        return {
            ...duck,
            eggProduction,
            farmingParams: {
                farmingPower,
                lastCheckFarmedAmount: globalInterest,
                globalFarmingPower: global,
                stakedBefore: !!duck.basePower,
                toClaim,
                claimed,
            },
        };
    };

    addRarityForAnimals = async <T extends { issuer: addressId; name: string; assetId: string }>(
        nfts: T[],
        { newDucks }: { newDucks: boolean } = { newDucks: false },
        type: ANIMAL_PREFIXES,
    ): Promise<Array<T & WithRarityParams>> => {
        const [{ byGenotypeAndGeneration }] = await Promise.all([
            this.getGenotypeAnimal(type),
            this.farmingService.fetchFarmingGlobals(),
        ]);

        const result = nfts.map(async (nft) => {
            const genotypeTemplate = this.helperService.getGenotypePattern(nft.name);
            const ducksWithSameGenes = byGenotypeAndGeneration[genotypeTemplate];
            const rarity = calculateRarity(newDucks ? ducksWithSameGenes + 1 : ducksWithSameGenes, nft);
            const {
                fp,
                global,
                stakedBefore: stakedBefore,
                toClaim,
                globalInterest,
                assetInterest,
                claimed,
            } = await this.farmingService.fetchFarmingPower(nft.assetId, nft.name, undefined);
            const editedFp = Math.floor((fp / 100) * Math.floor(rarity));
            const eggProduction = this.calcEggProduction(editedFp, global);
            return {
                ...nft,
                ducksWithSameGenes,
                rarity,
                farmingParams: {
                    farmingPower: editedFp,
                    globalFarmingPower: global,
                    stakedBefore: stakedBefore,
                    toClaim,
                    globalInterest,
                    assetInterest,
                    claimed,
                },
                eggProduction,
            };
        });
        return await Promise.all(result);
    };

    addRarity = async <T extends { issuer: addressId; name: string; assetId: string }>(
        nfts: T[],
        { newDucks }: { newDucks: boolean } = { newDucks: false },
    ): Promise<Array<T & WithRarityParams>> => {
        const [{ byGenotypeAndGeneration }, { globalStaked }] = await Promise.all([
            this.getGenotypeAmounts(),
            this.farmingService.fetchFarmingGlobals(),
        ]);
        const result = nfts.map(async (nft) => {
            if (!nft?.assetId) {
                console.log('NFT', nft);
            }
            const genotypeTemplate = this.helperService.getGenotypePattern(nft.name);
            const ducksWithSameGenes = byGenotypeAndGeneration[genotypeTemplate];

            const rarity = calculateRarity(newDucks ? ducksWithSameGenes + 1 : ducksWithSameGenes, nft);
            const {
                fp,
                global,
                stakedBefore: stakedBefore,
                toClaim,
                globalInterest,
                assetInterest,
                claimed,
            } = await this.farmingService.fetchFarmingPower(nft.assetId, nft.name, undefined);
            const editedFp = Math.floor((fp / 100) * Math.floor(rarity));
            const eggProduction = this.calcEggProduction(editedFp, global);
            return {
                ...nft,
                ducksWithSameGenes,
                rarity,
                farmingParams: {
                    farmingPower: editedFp,
                    globalFarmingPower: global,
                    stakedBefore: stakedBefore,
                    toClaim,
                    globalInterest,
                    assetInterest,
                    claimed,
                },
                eggProduction,
            };
        });
        return await Promise.all(result);
    };

    addRarityClean = async <T extends { issuer: addressId; name: string }>(
        nfts: T[],
        { newDucks }: { newDucks: boolean } = { newDucks: false },
    ): Promise<Array<T & { rarity: number }>> => {
        const [{ byGenotypeAndGeneration }] = await Promise.all([this.getGenotypeAmounts()]);
        const result = nfts.map(async (nft) => {
            const genotypeTemplate = this.helperService.getGenotypePattern(nft.name);
            const ducksWithSameGenes = byGenotypeAndGeneration[genotypeTemplate];
            const rarity = calculateRarity(newDucks ? ducksWithSameGenes + 1 : ducksWithSameGenes, nft);
            return {
                ...nft,
                ducksWithSameGenes,
                rarity,
            };
        });
        return await Promise.all(result);
    };

    addRarityCleanAnimals = async <T extends { issuer: addressId; name: string }>(
        nfts: T[],
        { newDucks }: { newDucks: boolean } = { newDucks: false },
        type: ANIMAL_PREFIXES,
    ): Promise<Array<T & { rarity: number }>> => {
        const [{ byGenotypeAndGeneration }] = await Promise.all([this.getGenotypeAnimal(type)]);
        const result = nfts.map(async (nft) => {
            const genotypeTemplate = this.helperService.getGenotypePattern(nft.name);
            const ducksWithSameGenes = byGenotypeAndGeneration[genotypeTemplate];
            const rarity = calculateRarity(newDucks ? ducksWithSameGenes + 1 : ducksWithSameGenes, nft);
            return {
                ...nft,
                ducksWithSameGenes,
                rarity,
            };
        });
        return await Promise.all(result);
    };
}

export default CommonProductionService;
