import { useQuery, type UseQueryResult } from 'react-query';

import type { IMaxToFeedWithSignature } from '$shared/types';
import { ONE_MINUTE } from '$shared/constants';

import babyDuckService from '$/services/api/baby-duck';

export const maxToFeedWithSignatureQueryKey = (address: addressId): string[] => [
    'duckling-max-to-feed-with-signature',
    address,
];

const useMaxToFeedWithSignature = (
    address: addressId,
    options: { deferred } = { deferred: false },
): UseQueryResult<IMaxToFeedWithSignature> =>
    useQuery(maxToFeedWithSignatureQueryKey(address), () => babyDuckService.fetchMaxToFeedWithSignature(address), {
        enabled: !options.deferred,
        staleTime: ONE_MINUTE,
    });

export default useMaxToFeedWithSignature;
