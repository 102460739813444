import React, { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import { DUCK_GENERATION_NAMES } from '$shared/constants';

import { getRarityName } from '$/components/old-pages/Portfolio/misc';
import { IProps as TagProps } from '$/components/common/Tag';
import { TTagsData } from '$/types/ui/tags';

const isBreedLater = (generationName) => {
    const sortedDuck = Object.keys(DUCK_GENERATION_NAMES).sort();
    const { name } = DUCK_GENERATION_NAMES[sortedDuck[sortedDuck.length - 1]];
    return generationName === name;
};

interface IGetTags extends TTagsData {
    t: TFunction;
}

type TGetTags = {
    isRender: boolean;
    content: ReactNode;
    id: string;
    mod?: string;
    theme?: TagProps['theme'];
    tooltipText?: string;
};

const getTags = (params: IGetTags): TGetTags[] => {
    const {
        assetId,
        bgColor = '',
        canBreed = true,
        duckNumber,
        generationName,
        generationNumber,
        name,
        rarity,
        rarityColor,
        t,
        withoutGeneration,
        huntCapacityLeft,
        huntCapacityTotal,
        huntPower,
        farmingPower,
        stakedBefore,
        houseType,
    } = params;

    return [
        {
            isRender: Boolean(duckNumber),
            content: `#${duckNumber}`,
            id: `num_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: true,
            content: name.replace('DUCK-', ''),
            id: `genotype_${assetId}`,
            theme: 'genotype',
            tooltipText: t('duck.genotype'),
        },
        {
            isRender: !withoutGeneration,
            content: generationName,
            id: `generation_${assetId}`,
            mod: bgColor,
            tooltipText: t('common.generation_tooltip', { number: generationNumber }),
        },
        {
            isRender: true,
            content: (
                <>
                    {t('duck.rarity')}
                    <strong>{rarity?.toFixed(0)}%</strong>
                </>
            ),
            id: `rarity_${assetId}`,
            mod: rarityColor,
            tooltipText: getRarityName(rarity, t),
        },
        {
            isRender: huntCapacityTotal > 0 && huntCapacityTotal > 0,
            content: (
                <>
                    {t('duck.capacity')}
                    <strong>
                        {huntCapacityLeft}/{huntCapacityTotal}{' '}
                    </strong>
                </>
            ),
            id: `capacity_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: huntPower > 0,
            content: (
                <>
                    {t('duck.huntPower')}
                    <strong>{huntPower.toFixed(0)}</strong>
                </>
            ),
            id: `power_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: stakedBefore,
            content: (
                <>
                    {t('duck.farmPower')}
                    <strong>{farmingPower.toFixed(0)}</strong>
                </>
            ),
            id: `fp_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: !stakedBefore,
            content: (
                <>
                    {t('duck.farmPower')}
                    <strong>~{farmingPower.toFixed(0)}</strong>
                </>
            ),
            id: `fp_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: isBreedLater(generationName),
            content: t('common.breed_later'),
            id: `breed_later_${assetId}`,
            theme: 'green',
        },
        {
            isRender: canBreed,
            content: t('common.can_breed'),
            id: `can_breed_${assetId}`,
            theme: 'green',
        },
        {
            isRender: !canBreed,
            content: t('common.sterile'),
            id: `sterile_${assetId}`,
            theme: 'red',
        },
        {
            isRender: !!houseType,
            content: t(`portfolio.tooltips.duck.art_${houseType?.title.replaceAll(' ', '_').toLowerCase()}.title`),
            id: `house_type_${assetId}`,
            theme: 'green',
            tooltipText: t(
                `portfolio.tooltips.duck.art_${houseType?.title.replaceAll(' ', '_').toLowerCase()}.description`,
                { value: houseType?.value ?? '' },
            ),
        },
    ];
};

export default getTags;
