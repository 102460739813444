import { ChangeEventHandler, FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import st from './Input.module.scss';

interface IProps extends React.HTMLProps<HTMLInputElement> {
    disabled?: boolean;
    className?: string;
    containerClassName?: string;
    postfixClassName?: string;
    id?: string;
    name: string;
    label?: string;
    placeholder?: string;
    type?: string;
    value: string;
    postfix?: ReactNode;
    onChange: ChangeEventHandler<HTMLInputElement>;
    bgColor?: string;
    padding?: string;
    borderRadius?: string;
    state?: 'default' | 'valid' | 'invalid';
}

const Input: FC<IProps> = (props) => {
    const {
        disabled,
        className,
        containerClassName,
        id,
        name,
        label,
        placeholder,
        type = 'text',
        state = 'default',
        value,
        onChange,
        postfix,
        bgColor,
        padding,
        borderRadius,
        postfixClassName,
        ...rest
    } = props;
    const customPropertiesRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (bgColor) {
            customPropertiesRef.current?.style.setProperty('--background-color', bgColor);
        } else {
            customPropertiesRef.current?.style.removeProperty('--background-color');
        }
        if (padding) {
            customPropertiesRef.current?.style.setProperty('--padding-size', padding);
        } else {
            customPropertiesRef.current?.style.removeProperty('--padding-size');
        }
        if (borderRadius) {
            customPropertiesRef.current?.style.setProperty('--border-radius', borderRadius);
        } else {
            customPropertiesRef.current?.style.removeProperty('--border-radius');
        }
    }, [customPropertiesRef, bgColor, padding, borderRadius]);

    const currentId = id || name;
    const classNames = cn(
        st.inputContainer,
        { [st.withValue]: value.length > 0, [st.withoutLabel]: !label },
        st[`${state}-state`],
    );

    return (
        <div className={cn(st.propsDefaults, containerClassName)}>
            <div className={classNames} ref={customPropertiesRef}>
                {postfix && <span className={cn(st.postfix, postfixClassName)}>{postfix}</span>}

                <input
                    className={cn(st.input, className)}
                    disabled={disabled}
                    id={currentId}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    {...rest}
                />

                {label && (
                    <label htmlFor={currentId} className={st.label}>
                        {label}
                    </label>
                )}
            </div>
        </div>
    );
};

export default Input;
