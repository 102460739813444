import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import Swal from '$/components/Swal';

import { Card } from '../Card';
import st from './HatchedCard.module.scss';

interface IProps {
    duckClaimedSwalOptions;
    className?: string;
    imgClassName?: string;
    onClick: () => void;
}

const HatchedCard: FC<IProps> = ({ duckClaimedSwalOptions, onClick, className, imgClassName }) => (
    <>
        <Card
            isAuth
            className={cn(st.card, className)}
            cover={<img src="/ducks/hatched.svg" alt="Hatched duck" className={cn(st.img, imgClassName)} />}
            footer={{
                text: <Trans i18nKey="incubator.claim.claim_btn_ready" />,
                className: st.footer,
                buttonClassName: st.btn,
            }}
            onClick={onClick}
        />

        {duckClaimedSwalOptions && <Swal options={duckClaimedSwalOptions} />}
    </>
);

export default HatchedCard;
