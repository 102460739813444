import type { IArtefact } from '$shared/types';

import artefactService from '$/services/api/artefact';

import StakableArtefact from './StakableArtefact';
import UsableOnDuckArtefact from './UsableOnDuckArtefact';
import UsableOnAccountArtefact from './UsableOnAccountArtefact';
import BreedingArtefact from './BreedingArtefact';
import NoUseArtefact from './NoUseArtefact';
import ArtefactInCooldown from './ArtefactInCooldown';
import WearableArtefact from './WearableArtefact';
import UsableOnArtefactArtifact from './UsableOnArtefactArtifact';
import MutariumArtefact from './MutariumArtefact';

interface Props {
    artefact: IArtefact;
    onUse?: (artefact: IArtefact) => Promise<void>;
    num?: number;
}

const Artefact = ({ artefact, onUse, num }: Props): JSX.Element => {
    const { type } = artefact;

    if (artefactService.isInCooldown(artefact)) {
        return <ArtefactInCooldown artefact={artefact} num={num} />;
    }

    if (artefactService.isStakeable(type)) {
        return <StakableArtefact artefact={artefact} onUse={onUse} num={num} />;
    }

    if (artefactService.isWearable(type)) {
        return <WearableArtefact artefact={artefact} onUse={onUse} num={num} />;
    }

    if (artefactService.isUsableOnDuck(type)) {
        return <UsableOnDuckArtefact artefact={artefact} onUse={onUse} num={num} />;
    }

    if (artefactService.isUsableOnArtefact(type)) {
        return <UsableOnArtefactArtifact artefact={artefact} onUse={onUse} num={num} />;
    }
    if (artefactService.isUsableOnAccount(type)) {
        return <UsableOnAccountArtefact artefact={artefact} onUse={onUse} num={num} />;
    }

    if (artefactService.isBreeding(type)) {
        return <BreedingArtefact artefact={artefact} num={num} />;
    }

    if (artefactService.isMutarium(type)) {
        return <MutariumArtefact artefact={artefact} onUse={onUse} num={num} />;
    }

    return <NoUseArtefact artefact={artefact} num={num} />;
};

export default Artefact;
