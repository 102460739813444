/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { IDuckDetails } from '$shared/types';
import { DUCK_COLORS } from '$shared/constants';
import { isBirthdayEvent } from '$shared/domain/events';
import { RentalDuckStatus } from '$shared/types/marketplaces';
import { getAddress } from '$shared/domain/constants';

import { useDuckDetails, useDuckParams } from '$/hooks/duck';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import getUrl from '$/utils/getUrl';
import ROUTES from '$/constants/routes';
import { getRarityColor } from '$/components/old-pages/Portfolio/misc';
import { EDuckTabs, TDuckCardFooter } from '$/types/duck';
import Title from '$/components/common/Title';
import DuckSoundPlayer from '$/components/duck/DuckSoundPlayer';
import DuckTags from '$/components/duck/DuckTags';
import Achievements from '$/components/duck/Achievements';
import { Props as ButtonProps } from '$/components/common/Button';
import { getToClaim } from '$/utils/ducks';
import useDuckHuntDetails from '$/hooks/duck/useHuntDetails';
import useDuckDetailsWithoutExtra from '$/hooks/duck/useDuckDetailsWithoutExtra';

import { Card, CardTemplate } from '../Card';
import { getButtonDisabled, getButtonText, getOnClick } from './config';
import st from './Duck.module.scss';
import DuckCoverContent from './DuckCoverContent';

export interface IProps {
    isAuth?: boolean;
    disabled?: boolean;
    withoutGeneration?: boolean;
    withoutExtra?: boolean;
    withoutLink?: boolean;
    withoutAuth?: boolean;
    buttonText?: ReactNode;
    buttonSize?: ButtonProps['size'];
    overlay?: ReactNode;
    params: IDuckDetails;
    onClick?: (params: IDuckDetails) => Promise<void>;
    className?: string;
    to?: string;
    secondSection?: ReactNode;
    secondSectionClassName?: string;
    imageClassName?: string;
    footer?: TDuckCardFooter;
    footerEl?: ReactNode;
}
/** @deprecated */
const Duck: FC<IProps> = (props) => {
    const {
        isAuth,
        disabled,
        withoutGeneration,
        withoutExtra,
        withoutLink,
        withoutAuth,
        buttonSize,
        buttonText,
        children,
        className,
        footer,
        footerEl,
        imageClassName,
        overlay,
        params,
        params: {
            assetId,
            canBreed,
            inWars,
            name,
            onFarming,
            onFarmingVegg,
            onSale,
            rarity,
            oldRarity,
            toClaim,
            inRental,
            status,
            huntPower,
            inHunt,
            farmingParams,
            achievements,
            veggFarmingParams,
        },
        secondSection,
        secondSectionClassName,
        to,
        onClick,
    } = props;

    const [cacheTs, setCacheTs] = useState(0);
    const { t } = useTranslation();

    const {
        bgColor,
        color = '',
        duckDetails,
        generationName,
        generationNumber,
        genoType,
    } = useDuckParams(params, { cacheTs }) || {};

    const href = to || getUrl(ROUTES.DUCK, { nftId: assetId, tab: EDuckTabs.MAIN });
    const { name: title, image } = duckDetails || {};
    let achievementsData = achievements;
    let huntData;
    let fp = farmingParams?.farmingPower;
    // console.log('OLD DUCK FRAME QUERIED');
    if (withoutExtra && (!achievements || !fp)) {
        const duckDetailsData = useDuckDetailsWithoutExtra(assetId);
        achievementsData = duckDetailsData.data?.achievements;
        fp = duckDetailsData.data?.farmingParams?.farmingPower;
    } else if (!achievements || !fp) {
        const duckDetailsData = useDuckDetails(assetId);
        achievementsData = duckDetailsData.data?.achievements;
        huntData = duckDetailsData.data?.hunt;
        fp = duckDetailsData.data?.farmingParams?.farmingPower;
    } else if (!withoutExtra) {
        // TODO: TYPES NEEDS FIXING
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        huntData = (useDuckHuntDetails(assetId).data as any)?.hunt;
    } else {
        // console.log(`Duck without extra`);
    }
    let house: { title: string; value: number } | null = null;
    const megaHouse = getAddress('MEGA_DUCK_HOUSE_DAPP');
    const duckHouse = getAddress('DUCK_HOUSE_DAPP');
    const xmasStable = getAddress('XMAS_STBLE_DAPP');
    const locks = (params as unknown as { locks: Array<{ dApp: string }> }).locks;
    if (locks) {
        for (const lock of locks)
            if (lock.dApp === megaHouse) {
                house = { title: 'MEGA DUCK HOUSE', value: 15 };
            } else if (lock.dApp === duckHouse) {
                house = { title: 'DUCK HOUSE', value: 30 };
            } else if (lock.dApp === xmasStable) {
                house = { title: 'XMAS STABLE', value: 5 };
            }
    }

    const tagsParams = {
        assetId,
        bgColor,
        canBreed,
        duckNumber: achievementsData?.n,
        generationName,
        generationNumber,
        name,
        rarity: oldRarity || rarity,

        rarityColor: getRarityColor(rarity),
        withoutGeneration,
        huntCapacityTotal: huntData ? huntData.totalCapacity : -1,
        huntCapacityLeft: huntData ? Math.max(huntData.totalCapacity - huntData.laidEggs, 0) : -1,
        huntPower: huntPower || -1234,
        farmingPower: fp || 0,
        stakedBefore: farmingParams?.stakedBefore === true,
        houseType: house,
    };
    const claim = useMemo(() => {
        if (onFarming) {
            return Number((getToClaim(farmingParams?.toClaim || toClaim) / 1e6).toFixed(4));
        }
        return Number(((toClaim ?? 0) / 100).toFixed(4));
    }, [onFarming, toClaim]);

    const veggClaim = useMemo(() => {
        if (onFarmingVegg) {
            return Number((getToClaim(veggFarmingParams?.toClaim || toClaim) / 1e8).toFixed(4));
        }
        return Number(((veggFarmingParams?.toClaim ?? 0) / 100).toFixed(4));
    }, [onFarmingVegg, veggFarmingParams?.toClaim]);

    const clickHandler = useEffectiveCallback(async () => {
        await onClick?.(params);
        setCacheTs(Date.now());
    });

    const Component = withoutAuth ? CardTemplate : Card;
    const bg = isBirthdayEvent() ? `center / cover url("/ducks/birthday-bg/${color}.svg")` : `#${DUCK_COLORS[color]}`;

    const defaultFooter = {
        buttonSize,
        disabled: getButtonDisabled({
            disabled,
            withoutLink,
            toClaim,
            veggClaim: veggFarmingParams?.toClaim || 0,
            onClick,
            onFarmingVegg,
            onFarming,
        }),
        text: getButtonText({
            onSale,
            onFarming,
            onFarmingVegg,
            inWars,
            claim,
            veggClaim: veggFarmingParams?.toClaim || 0,
            buttonText,
            t,
            inRental,
            status: status as RentalDuckStatus,
            inHunt,
        }),
    };

    return (
        <Component
            className={className}
            secondSectionClassName={secondSectionClassName}
            isAuth={isAuth}
            background={bg}
            coverClassName={cn({ [st.video]: genoType === 'WWWWWZOE' }, imageClassName)}
            cover={
                <>
                    <DuckCoverContent genoType={genoType} image={image} title={title} />
                    {overlay}
                </>
            }
            footerEl={footerEl}
            footer={footer || defaultFooter}
            {...{
                secondSection:
                    genoType === 'WWWWSXSR' ? <DuckSoundPlayer audioSrc="/audio/duck_WWWWSXSR.mp3" /> : secondSection,
            }}
            {...(!withoutLink && { to: href })}
            {...(onClick && {
                onClick: getOnClick({
                    claim,
                    veggClaim,
                    onClick: clickHandler,
                    onFarmingVegg,
                    onFarming,
                }),
            })}
        >
            <Title as="h3" size="h2" className={st.title}>
                {title}
            </Title>

            <DuckTags data={tagsParams} className={st.tags} />

            <Achievements className={st.achievements} items={achievementsData?.a} itemClassName={st.achievement} />

            {children}
        </Component>
    );
};

export default Duck;
