import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Tag, { IProps as TagProps } from '$/components/common/Tag';
import { TTagsTurtleData } from '$/types/ui/tags';

import getTags from './config';
import st from './TurtleTags.module.scss';

interface IProps {
    className?: string;
    tagClassName?: string;
    data: TTagsTurtleData;
    size?: TagProps['size'];
}

const TurtleTags: FC<IProps> = ({ className, data, size = 'small', tagClassName }) => {
    const { t } = useTranslation();

    return (
        <div className={cn(st.tags, className)}>
            {getTags({ ...data, t }).map(({ isRender, content, id, mod, tooltipText, theme }) => {
                if (!isRender) {
                    return null;
                }

                return (
                    <Tag
                        key={id}
                        id={id}
                        className={cn(st.tag, mod, tagClassName)}
                        theme={theme}
                        size={size}
                        tooltipText={tooltipText}
                    >
                        {content}
                    </Tag>
                );
            })}
        </div>
    );
};

export default TurtleTags;
