import type { Duckling, EntityListResponse, EntityResponse, IMaxToFeedWithSignature } from '$shared/types';
import { int, urlString } from '$shared/utils';
import { isAddress } from '$shared/domain/guards';
import CommonBabyDuckService from '$shared/services/baby-duck';
import { FaultyDuckling } from '$shared/types/ducklings';

import { API_URL } from '$/constants/urls';

import helperService from '../helper';
import assetsService from '../assets';
import genesisDucklingService from '../genesis-duckling';

class FrontendBabyDuckService extends CommonBabyDuckService {
    helperService = helperService;

    assetsService = assetsService;

    genesisDucklingService = genesisDucklingService;

    /**
     * Returns detailed information about the baby duck
     * @param {addressId} address
     * @return {Promise<IDuckling>}
     */
    fetchBabyDucksForAddress = async (address: addressId): Promise<EntityListResponse<Duckling>> => {
        const { data } = await this.helperService.http.get<EntityListResponse<Duckling>>(
            urlString(`${API_URL}/v2/addresses/${address}/ducklings`, { size: 1000 }),
        );

        return data;
    };

    fetchFaultyBabyDucks = async (): Promise<FaultyDuckling[]> => {
        const { data } = await this.helperService.http.get<EntityResponse<FaultyDuckling[]>>(
            `${API_URL}/v2/ducklings/faulty`,
        );

        return data?.entity ?? [];
    };

    /**
     * Returns detailed information about the baby duck
     * @param {string} ducklingId
     * @return {Promise<IDuckling>}
     */
    fetchBabyDuckDetails = async (ducklingId: ducklingId): Promise<Duckling | null> => {
        const { data } = await this.helperService.http.get<EntityResponse<Duckling>>(
            `${API_URL}/v2/ducklings/${ducklingId}`,
        );

        return data?.entity ?? null;
    };

    fetchMaxToFeedWithSignature = async (address: addressId): Promise<IMaxToFeedWithSignature> => {
        if (!isAddress(address)) {
            return {
                maxToFeed: int(0),
                signature: '',
                userNonce: int(0),
            };
        }

        const { data: backendSignature } = await this.helperService.http.get<IMaxToFeedWithSignature>(
            `${API_URL}/v2/ducklings/feed/${address}`,
        );

        return backendSignature;
    };
}

export default FrontendBabyDuckService;
