/* eslint-disable @typescript-eslint/no-explicit-any */
import Color from 'color';

import CommonBreedingService from '$shared/services/breeding';

import { DUXPLORER_URL } from '$/constants';

import helperService from '../helper';
import assetsService from '../assets';
import transactionService from '../transaction';

class FrontendBreedingService extends CommonBreedingService {
    DUXPLORER_URL = DUXPLORER_URL;

    helperService = helperService;

    transactionService = transactionService;

    assetsService = assetsService;

    groupByFarmPower = (possiblePatternsWithFarmPower: any[]): any[] => {
        const color = Color('#41a4ff');
        const groupedByFarmPower: Record<string, any> = {};
        possiblePatternsWithFarmPower.forEach((pattern) => {
            const farmPower = pattern.farmPower;
            const key = `${farmPower}`;
            if (!groupedByFarmPower.hasOwnProperty(key)) {
                groupedByFarmPower[key] = {
                    probability: 0,
                    farmPower: pattern.farmPower,
                    genotype: pattern.genotype,
                };
            }
            groupedByFarmPower[key].probability += pattern.probability;
        });
        return Object.values(groupedByFarmPower)
            .sort((a: any, b: any) => {
                const keyA = parseFloat(a.farmPower);
                const keyB = parseFloat(b.farmPower);
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            })
            .map((val, index) => ({
                ...val,
                color: color.darken(0.23 * index).hex(),
            }));
    };
}

export default FrontendBreedingService;
