import type { WarsState } from '$shared/types';
import { WarsStage } from '$shared/enums';
import { shuffle } from '$shared/utils';

import {
    CLEANUP_GAMEPLAY,
    GAME_OVER,
    GAMEPLAY_INITIALIZED,
    GAMEPLAY_STARTING,
    LOAD_FARMS_ERROR,
    LOAD_FARMS_SUCCESS,
    LOAD_GAME_DATA,
    LOAD_GAME_DATA_ERROR,
    LOAD_GAME_DATA_SUCCESS,
    LOAD_LEADERBOARD_SUCCESS,
    START_GAME,
    START_MATCHMAKING,
    UPDATE_DUCK,
    WarsAction,
} from '../actions/wars.actions';

const getInitialState = (): WarsState => ({
    _id: '',
    ftue: true,
    slug: '',
    text: '',
    caption: '',
    duck: null,
    stage: WarsStage.Loading,
    server: '',
    error: '',
    farm: null,
    farms: null,
    playerId: '',
    opponentId: '',
    outcome: null,
    leaderboard: null,
    endDate: 0,
    farmsStats: {},
    gameStats: {
        playersCount: 0,
    },
    playersStats: {
        wins: 0,
        loses: 0,
        draws: 0,
        position: 0,
        earned: 0,
        winsFactor: 0,
    },
    base: 0,
    coefficient: 0,
    subSum: 0,
    budget: 0,
    wins: 0,
    winsFactor: 0,
    poolWinsFactor: 0,
});

export const initialWarsState: WarsState = getInitialState();
// eslint-disable-next-line default-param-last
export default function warsReduces(state: WarsState = initialWarsState, action: WarsAction): WarsState {
    switch (action.type) {
        case LOAD_GAME_DATA: {
            const newState = {
                ...state,
                ftue: false,
            };

            if (!action.payload) {
                newState.stage = WarsStage.Loading;
            }

            return newState;
        }
        case LOAD_GAME_DATA_SUCCESS: {
            const newState = {
                ...state,
                ...action.payload,
            };

            if (!action.payload.silent) {
                newState.stage = WarsStage.Lobby;
            }

            return newState;
        }
        case LOAD_GAME_DATA_ERROR:
            return {
                ...state,
                stage: WarsStage.Error,
                error: action.payload.error,
            };
        case LOAD_FARMS_SUCCESS:
            return {
                ...state,
                farms: shuffle(action.payload),
            };
        case LOAD_FARMS_ERROR:
            return {
                ...state,
                farms: [],
            };
        case LOAD_LEADERBOARD_SUCCESS:
            return {
                ...state,
                leaderboard: action.payload,
            };
        case UPDATE_DUCK:
            return {
                ...state,
                duck: action.payload,
            };
        case START_MATCHMAKING:
            return {
                ...state,
                stage: WarsStage.Matchmaking,
            };
        case GAMEPLAY_INITIALIZED:
            return {
                ...state,
                stage: WarsStage.Sign,
                playerId: action.payload.playerId,
                opponentId: action.payload.opponentId,
            };
        // TODO: check if this is used somewhere / seems like some states are not being used and so redundant?
        case GAMEPLAY_STARTING:
            return {
                ...state,
                stage: WarsStage.Starting,
            };
        case START_GAME:
            return {
                ...state,
                stage: WarsStage.Battle,
            };
        case GAME_OVER:
            return {
                ...state,
                stage: WarsStage.Result,
                outcome: { ...action.payload },
            };
        case CLEANUP_GAMEPLAY:
            return {
                ...getInitialState(),
                stage: WarsStage.Loading,
            };
    }

    return state;
}
