import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { noop } from '$shared/utils';
import { getAddress } from '$shared/domain/constants';

import Modal from '$/components/common/Modal';
import Title from '$/components/common/Title';
import Text from '$/components/common/Text';
import Input from '$/components/common/Input';
import Checkbox from '$/components/common/Checkbox';
import Button from '$/components/common/Button';
import { useOnEscapePress } from '$/hooks/useToggleOpen';
import { useCheckboxState, useInputState, useSubmitOnEnterPress } from '$/hooks/form-elements';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import useAuth from '$/hooks/useAuth';
import { setJuneFirstCharityModalAppearance } from '$/browser/localStorage';
import transactionService from '$/services/api/transaction';
import { authService } from '$/services/authentication';
import { showErrorMessage, showSuccessMessage } from '$/services/utils';
import state from '$/state';

import classes from './CharityFirstJuneModal.module.css';

const CHARITY_ADDRESS = getAddress('CHARITY');

const CharityFirstJuneModal = (): JSX.Element => {
    const { t } = useTranslation();
    const { authType } = useAuth();
    const [isOpen, setIsOpen] = useRecoilState(state.modals.childrenDayCharity.visibilityAtom);
    const [amount, setAmount] = useInputState('');
    const [isNeverShow, setIsNeverShow] = useCheckboxState(false, setJuneFirstCharityModalAppearance);
    const closeModal = useEffectiveCallback(() => setIsOpen(false));

    const submit = useEffectiveCallback(async () => {
        try {
            const { waitForTransaction } = await transactionService.invokeAuthService(
                authService.transferEggs,
                authType,
                CHARITY_ADDRESS,
                parseFloat(amount),
            );

            await waitForTransaction();
            closeModal();
            showSuccessMessage(t, 'Eggs transferred successfully!');
        } catch (e) {
            transactionService.handleException(e, ({ message }) => showErrorMessage(t, message, e));
            setIsOpen(true);
        }
    });

    useSubmitOnEnterPress(submit);
    useOnEscapePress(isOpen ? closeModal : noop);

    return (
        <Modal isShown={isOpen} onClose={closeModal} className={classes.content}>
            <Title className={classes.title}>Care to make a donation?</Title>
            <Text className={classes.text} block size="small">
                Today is June 1st, International Children&apos;s Day. For this event, we launched a campaign to raise
                funds for donations to{' '}
                <a href="https://www.savethechildren.org/" target="_blank" rel="noreferrer">
                    Save the Children
                </a>{' '}
                organization. And you have the opportunity to be a part of this campaign
            </Text>
            <Input
                name="donation-amount"
                label="amount"
                type="number"
                placeholder="0.2"
                value={amount}
                onChange={setAmount}
            />

            <Checkbox
                id="show-no-more"
                name="show-no-more"
                className={classes.checkbox}
                checked={isNeverShow}
                onChange={setIsNeverShow}
            >
                <Text size="small">Do not show me again</Text>
            </Checkbox>

            <div className={classes.buttons}>
                <Button color="black" onClick={closeModal}>
                    Close
                </Button>
                <Button color="default" onClick={submit}>
                    Donate
                </Button>
            </div>
        </Modal>
    );
};

export default CharityFirstJuneModal;
