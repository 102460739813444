import React, { createRef, FC, useState, MouseEvent } from 'react';
import cn from 'classnames';

import st from './DuckSoundPlayer.module.scss';

interface DuckSoundPlayerProps {
    large?: boolean;
    audioSrc: string;
    className?: string;
}

const DuckSoundPlayer: FC<DuckSoundPlayerProps> = ({ large, className, audioSrc }) => {
    const audioRef = createRef<HTMLAudioElement>();
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const togglePlayAudio = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const { current: audio } = audioRef;

        if (!audio) {
            return;
        }

        if (isAudioPlaying) {
            audio.pause();
            audio.currentTime = 0;
            setIsAudioPlaying(false);
        } else {
            audio.play().then(() => setIsAudioPlaying(true));
        }
    };

    return (
        <div className={cn(st.player, { [st.playing]: isAudioPlaying, [st.large]: large }, className)}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio loop className={st.audio} ref={audioRef} src={audioSrc} />
            <button type="button" onClick={togglePlayAudio} className={st.play} />
            <div className={st.track} />
        </div>
    );
};

export default DuckSoundPlayer;
