import { AxiosError } from 'axios';

import type { AdminEgg, AssetDetails, AuthSignature, HuntReward, HuntUserStats, WithHuntParams } from '$shared/types';
import { isAddress } from '$shared/domain/guards';
import { getAddress } from '$shared/domain/constants';
import { encodeHuntShot } from '$shared/domain/hunt';
import { headersJson } from '$shared/constants';
import CommonThirdPartyService from '$shared/services/third-party';
import { IDuckUnstackRequestResponse, IRopeEggPriceWithSignature, IUnstakeDuckSignature } from '$shared/types/ducks';
import { urlString } from '$shared/utils';
import { ICheat, RangeInfo } from '$shared/types/hunt';

import type { HuntUserSettings, LongLat } from '$/types';
import { API_URL, NODE_URL } from '$/constants';
import helperService from '$/services/api/helper';
import { getDeviceId } from '$/browser/localStorage';
import {
    AdminHuntCaughtEggsStatsForCalendar,
    AdminHuntDailyCaughtEggsReward,
    AdminPlayerInfo,
    AdminUserWithEggs,
} from '$/types/duck';
import { Config, HuntBooster, LeaderboardRes, LeaderboardRow, LivenessCheck, UserInfo } from '$/types/hunt';

const HUNT_DAPP_ADDRESS = getAddress('HUNT_DAPP');
const DUCK_CAPACITY_ADDRESS = getAddress('DUCK_CAPACITY_DAPP');

type UserStats = {
    maxEggsPerDay: number;
    totalCapacity: number;
    openedEggsLast24h: number;
    totalEarned: number;
    totalOpened: number;
};

class FrontendDuckHuntService extends CommonThirdPartyService {
    protected DAPP_ADDRESS = HUNT_DAPP_ADDRESS;

    protected getAuthParams = (authSignature: AuthSignature): { s: string; t: number; k: string } => ({
        s: authSignature.signature,
        t: authSignature.timestamp,
        k: authSignature.publicKey,
    });

    helperService = helperService;

    openGoldenEgg = async ({
        eggId,
        address = 'unknown',
        signature = 'unknown',
        location: { lat, lng },
    }: {
        eggId: string;
        address?: addressId;
        signature?: string;
        location: LongLat;
    }): Promise<HuntReward> => {
        try {
            const { data } = await helperService.http.post<HuntReward>(
                `${API_URL}/hunt/v1/open`,
                {
                    eggId,
                    address: address || 'unknown',
                    signature: signature || 'unknown',
                    deviceId: getDeviceId(),
                    location: {
                        type: 'Point',
                        coordinates: [lng, lat],
                    },
                },
                headersJson,
            );

            return data;
        } catch (e) {
            const error = e as AxiosError;
            throw new Error(error?.response?.data?.message as string | undefined);
        }
    };

    sendScreenshot = async ({
        eggId,
        image,
        address,
        signature,
    }: {
        eggId: string;
        image: string;
        address?: addressId;
        signature?: string;
    }): Promise<void> => {
        await helperService.http.post(
            `${API_URL}/hunt/v1/shot`,
            {
                eggId,
                imageBase64: encodeHuntShot(image),
                address: address || 'unknown',
                signature: signature || 'unknown',
                deviceId: getDeviceId(),
            },
            headersJson,
        );
    };

    calculateDiffEggs = async ({ oldEggs, newEggs }: { oldEggs: integer; newEggs: integer }): Promise<integer> => {
        const response = await helperService.http.post(
            `${NODE_URL}/utils/script/evaluate/${DUCK_CAPACITY_ADDRESS}`,
            {
                expr: `calculateDiffEggs(${oldEggs}, ${newEggs})`,
            },
            headersJson,
        );
        // eslint-disable-next-line no-underscore-dangle
        return response.data.result.value._2.value;
    };

    fetchDucksOnAddress = async (address: addressId): Promise<Array<AssetDetails & WithHuntParams>> => {
        const { data } = await helperService.http.get<Array<AssetDetails & WithHuntParams>>(
            `${API_URL}/hunt/v1/address/${address}/ducks/all`,
        );

        return data;
    };

    fetchStatsOnDeviceId = async (deviceId: string): Promise<UserStats> => {
        const { data } = await helperService.http.get(`${API_URL}/hunt/v1/devices/${deviceId}/stats`);

        return data;
    };

    fetchStatsOnDuck = async (duckId: duckId): Promise<AssetDetails & WithHuntParams> => {
        const { data } = await helperService.http.get<AssetDetails & WithHuntParams>(
            `${API_URL}/hunt/v1/ducks/${duckId}`,
        );

        return data;
    };

    fetchRechargeCapacityOnDuck = async (duckId: duckId): Promise<AssetDetails & WithHuntParams> => {
        const { data } = await helperService.http.get<AssetDetails & WithHuntParams>(
            `${API_URL}/hunt/v1/ducks/${duckId}/recharge`,
        );

        return data;
    };

    fetchUserStats = async (userAddress: addressId): Promise<HuntUserStats> => {
        if (isAddress(userAddress)) {
            const { data } = await helperService.http.get<HuntUserStats>(
                `${API_URL}/hunt/v1/address/${userAddress}/stats`,
            );
            return data;
        }

        const { data } = await helperService.http.get<HuntUserStats>(
            `${API_URL}/hunt/v1/devices/${getDeviceId()}/stats`,
        );

        return data;
    };

    fetchUserRange = async (userAddress: addressId): Promise<RangeInfo> => {
        const { data } = await helperService.http.get<RangeInfo>(`${API_URL}/hunt/v1/address/${userAddress}/range`);
        return data;
    };

    fetchDeviceSettings = async (): Promise<Partial<HuntUserSettings>> => {
        const { data } = await helperService.http.get<Partial<HuntUserSettings>>(
            `${API_URL}/hunt/v1/devices/${getDeviceId()}/settings`,
        );
        return data;
    };

    fetchUserSettings = async (userAddress: addressId): Promise<Partial<HuntUserSettings>> => {
        if (isAddress(userAddress)) {
            const { data } = await helperService.http.get<Partial<HuntUserSettings>>(
                urlString(`${API_URL}/hunt/v1/address/${userAddress}/settings`, { deviceId: getDeviceId() }),
            );
            return data;
        }

        return {};
    };

    fetchCaughtEggs = async ({
        userAddressOrDeviceId,
        authSignature,
        from,
        to,
    }: {
        userAddressOrDeviceId?: addressId | string;
        authSignature: AuthSignature;
        from?: string;
        to?: string;
    }): Promise<AdminEgg[]> => {
        const url = isAddress(userAddressOrDeviceId)
            ? `${API_URL}/hunt/v1/address/${userAddressOrDeviceId}/eggs`
            : `${API_URL}/hunt/v1/devices/${userAddressOrDeviceId}/eggs`;

        const { data } = await helperService.http.get<AdminEgg[]>(url, {
            params: {
                ...this.getAuthParams(authSignature),
                from,
                to,
            },
        });

        return data;
    };

    fetchCaughtEggsByPeriod = async ({
        userAddressOrDeviceId,
        authSignature,
        from,
        to,
    }: {
        userAddressOrDeviceId?: addressId | string;
        authSignature: AuthSignature;
        from?: string;
        to?: string;
    }): Promise<AdminUserWithEggs[]> => {
        const url = `${API_URL}/hunt/v1/caught-eggs`;

        const { data } = await helperService.http.get<AdminUserWithEggs[]>(url, {
            params: {
                ...this.getAuthParams(authSignature),
                from,
                to,
                userAddressOrDeviceId,
            },
        });

        return data;
    };

    fetchNotPaidUserWithDucks = async ({
        authSignature,
    }: {
        authSignature: AuthSignature;
    }): Promise<AdminUserWithEggs[]> => {
        const url = `${API_URL}/hunt/v1/not-paid-users-with-ducks`;

        const { data } = await helperService.http.get<AdminUserWithEggs[]>(url, {
            params: this.getAuthParams(authSignature),
        });

        return data;
    };

    fetchHuntEggRewards = async ({
        date,
        authSignature,
    }: {
        date?: string;
        authSignature: AuthSignature;
    }): Promise<AdminHuntDailyCaughtEggsReward | null> => {
        const url = `${API_URL}/hunt/v1/egg-reward/${date}`;

        const { data } = await helperService.http.get<AdminHuntDailyCaughtEggsReward | null>(url, {
            params: this.getAuthParams(authSignature),
        });

        return data;
    };

    fetchHuntUserInfo = async ({
        address,
        authSignature,
    }: {
        address: addressId | string;
        authSignature: AuthSignature;
    }): Promise<AdminPlayerInfo> => {
        const url = `${API_URL}/hunt/v1/user-info/${address}`;
        const { data } = await helperService.http.get<AdminPlayerInfo>(url, {
            params: this.getAuthParams(authSignature),
        });

        return data;
    };

    fetchHuntUserLevel = async (address: addressId): Promise<UserInfo> => {
        const { data } = await helperService.http.get(`${API_URL}/hunt/v1/users/${address}`, headersJson);
        return data;
    };

    fetchHuntEggRewardsMonthly = async ({
        from,
        to,
        authSignature,
    }: {
        from?: string;
        to?: string;
        authSignature: AuthSignature;
    }): Promise<AdminHuntDailyCaughtEggsReward[]> => {
        const url = `${API_URL}/hunt/v1/egg-reward`;

        const { data } = await helperService.http.get<AdminHuntDailyCaughtEggsReward[]>(url, {
            params: {
                ...this.getAuthParams(authSignature),
                from,
                to,
            },
        });

        return data;
    };

    fetchCaughtEggsCalendar = async ({
        authSignature,
        from,
        to,
    }: {
        authSignature: AuthSignature;
        from?: string;
        to?: string;
    }): Promise<AdminHuntCaughtEggsStatsForCalendar[]> => {
        const url = `${API_URL}/hunt/v1/caught-eggs/calendar`;

        const { data } = await helperService.http.get<AdminHuntCaughtEggsStatsForCalendar[]>(url, {
            params: {
                ...this.getAuthParams(authSignature),
                from,
                to,
            },
        });

        return data;
    };

    fetchOpenedEggsCalendar = async ({
        from,
        to,
    }: {
        from?: string;
        to?: string;
    }): Promise<AdminHuntCaughtEggsStatsForCalendar[]> => {
        const url = `${API_URL}/hunt/v1/opened-eggs/calendar`;
        const { data } = await helperService.http.get<AdminHuntCaughtEggsStatsForCalendar[]>(url, {
            params: {
                from,
                to,
            },
        });
        return data;
    };

    blockUser = async ({
        cheat,
        date,
        authSignature,
    }: {
        cheat: ICheat;
        date: string;
        authSignature: AuthSignature;
    }): Promise<ICheat> => {
        const url = `${API_URL}/hunt/v1/block-user`;

        const { data } = await helperService.http.post<ICheat>(
            url,
            {
                cheat,
                date,
            },
            {
                params: this.getAuthParams(authSignature),
            },
        );

        return data;
    };

    unblockUser = async ({
        userAddress,
        date,
        authSignature,
    }: {
        userAddress: string;
        date: string;
        authSignature: AuthSignature;
    }): Promise<void> => {
        const url = `${API_URL}/hunt/v1/unblock-user`;

        await helperService.http.post<void>(
            url,
            {
                userAddress,
                date,
            },
            {
                params: this.getAuthParams(authSignature),
            },
        );
    };

    payUserRewards = async ({
        userAddress,
        authSignature,
    }: {
        userAddress: string;
        authSignature: AuthSignature;
    }): Promise<void> => {
        const url = `${API_URL}/hunt/v1/pay-user-rewards`;

        await helperService.http.post<void>(
            url,
            {
                userAddress,
            },
            {
                params: this.getAuthParams(authSignature),
            },
        );
    };

    removeSubmittedCheat = async ({
        userAddress,
        authSignature,
    }: {
        userAddress: string;
        authSignature: AuthSignature;
    }): Promise<void> => {
        const url = `${API_URL}/hunt/v1/remove-submit-cheat`;

        await helperService.http.post<void>(
            url,
            {
                userAddress,
            },
            {
                params: this.getAuthParams(authSignature),
            },
        );
    };

    submitReward = async ({ authSignature, date }: { authSignature: AuthSignature; date: string }): Promise<void> => {
        const url = `${API_URL}/hunt/v1/submit-reward`;

        await helperService.http.post(urlString(url, this.getAuthParams(authSignature)), {
            date,
        });
    };

    payReward = async ({
        authSignature,
        huntEggRewardId,
    }: {
        authSignature: AuthSignature;
        huntEggRewardId: string;
    }): Promise<void> => {
        const url = `${API_URL}/hunt/v1/pay-reward`;

        await helperService.http.post(
            url,
            {
                huntEggRewardId,
            },
            {
                params: this.getAuthParams(authSignature),
            },
        );
    };

    fetchDeletedEggs = async (
        addressOrDeviceId: addressId | string,
        authSignature: AuthSignature,
    ): Promise<AdminEgg[]> => {
        const url = isAddress(addressOrDeviceId)
            ? `${API_URL}/hunt/v1/address/${addressOrDeviceId}/deleted-eggs`
            : `${API_URL}/hunt/v1/devices/${addressOrDeviceId}/deleted-eggs`;

        const { data } = await helperService.http.get<AdminEgg[]>(url, {
            params: this.getAuthParams(authSignature),
        });

        return data;
    };

    fetchRopeEggPriceWithSignature = async ({
        address,
        eggId,
    }: {
        address: addressId;
        eggId: string | undefined;
    }): Promise<IRopeEggPriceWithSignature | undefined> => {
        if (!eggId) {
            return;
        }

        const { data: backendSignature } = await helperService.http.get<IRopeEggPriceWithSignature>(
            `${API_URL}/hunt/address/${address}/egg/${eggId}/rope`,
        );

        return backendSignature;
    };

    updateDeviceSettings = async (settings: Partial<HuntUserSettings>): Promise<void> => {
        const { data } = await helperService.http.patch<void>(
            `${API_URL}/hunt/v1/devices/${getDeviceId()}/settings`,
            settings,
        );
        return data;
    };

    updateUserSettings = async (
        userAddress: addressId,
        signature: AuthSignature,
        settings: Partial<HuntUserSettings>,
    ): Promise<void> => {
        if (isAddress(userAddress)) {
            const { data } = await helperService.http.patch<void>(
                urlString(`${API_URL}/hunt/v1/address/${userAddress}/settings`, { deviceId: getDeviceId() }),
                { ...signature, ...settings },
            );
            return data;
        }

        return this.updateDeviceSettings(settings);
    };

    getLeaderboard = async (address: string, level?: number): Promise<LeaderboardRow[]> => {
        const { data } = await helperService.http.get<LeaderboardRes>(
            urlString(`${API_URL}/hunt/v1/leaderboard`, { level }),
        );

        return data.users.map((user, i) => ({
            index: i + 1,
            address: user.address,
            eggs: user.eggs,
            level: user.level,
            isHighlight: i === 0,
            isCurrentUser: user.address === address,
        }));
    };

    requestToUnstakeDuck = async (
        {
            duckId,
            address,
        }: {
            duckId: string;
            address: addressId;
        },
        authSignature: AuthSignature,
    ): Promise<IDuckUnstackRequestResponse> => {
        try {
            const { data } = await helperService.http.post<IDuckUnstackRequestResponse>(
                `${API_URL}/hunt/v1/ducks/${duckId}/unlock-requests`,
                {
                    address,
                },
                {
                    ...headersJson,
                    params: this.getAuthParams(authSignature),
                },
            );

            return data;
        } catch (e: unknown) {
            const error = e as AxiosError;
            throw new Error(error?.response?.data?.message);
        }
    };

    fetchUnstakeDuckSignature = async ({
        duckId,
    }: {
        duckId: string;
        address: addressId;
    }): Promise<IUnstakeDuckSignature> => {
        try {
            const { data } = await helperService.http.get<IUnstakeDuckSignature>(
                `${API_URL}/hunt/v1/ducks/${duckId}/unlock-requests`,
                headersJson,
            );

            return data;
        } catch (e: unknown) {
            const error = e as AxiosError;
            throw new Error(error?.response?.data?.message);
        }
    };

    gesturesDetection = async ({
        image,
        address,
        signature,
        publicKey,
        timestamp,
    }: {
        image: string;
        address: addressId;
        signature: string;
        publicKey: string;
        timestamp: number;
    }): Promise<{ status: string }> => {
        const signatureParams = {
            s: signature,
            t: timestamp,
            k: publicKey,
        };
        const { data } = await helperService.http.post<{ status: string }>(
            `${API_URL}/hunt/v1/gestures-detection`,
            {
                imageBase64: encodeHuntShot(image),
                address: address || 'unknown',
                deviceId: await getDeviceId(),
            },
            {
                ...headersJson,
                params: signatureParams,
            },
        );
        return data;
    };

    checkLiveness = async ({ address }: { address: addressId }): Promise<LivenessCheck> => {
        const { data } = await helperService.http.post(
            `${API_URL}/hunt/v1/users/${address}/check-liveness`,
            {},
            headersJson,
        );
        return data;
    };

    getConfig = async (): Promise<Config> => {
        const { data } = await helperService.http.get<Config>(`${API_URL}/hunt/v1/config`);
        return data;
    };

    fetchHuntBoosters = async (userAddress: addressId): Promise<HuntBooster[]> => {
        if (isAddress(userAddress)) {
            const { data } = await helperService.http.get<HuntBooster[]>(
                urlString(`${API_URL}/hunt/v1/address/${userAddress}/boosters`),
            );
            return data;
        }

        return [];
    };

    activateBooster = async ({
        userAddress,
        authSignature,
        boosterType,
        location: { lat, lng },
    }: {
        userAddress: string;
        authSignature: AuthSignature;
        boosterType: string;
        location: LongLat;
    }): Promise<void> => {
        const url = `${API_URL}/hunt/v1/activate-booster`;

        await helperService.http.post<void>(
            url,
            {
                userAddress,
                boosterType,
                location: {
                    type: 'Point',
                    coordinates: [lng, lat],
                },
            },
            {
                params: this.getAuthParams(authSignature),
            },
        );
    };
}

export default FrontendDuckHuntService;
