import React, { ReactNode, useEffect, useState } from 'react';
import type { Store } from 'redux';
import { QueryClient } from 'react-query';

import { default as loggerService, ErrorBoundary } from '$/services/LoggerService';
import appConfigsService from '$/services/api/app-configs';

import ReactReduxProvider from './ReactReduxProvider';
import ReactQueryProvider from './ReactQueryProvider';
import RecoilProvider from './RecoilProvider';

interface Props {
    reduxStore: Store;
    queryClient: QueryClient;
    children: ReactNode;
}

loggerService.init();

const configsPromise = appConfigsService.fetchConfigs();

const AppProvider = ({ queryClient, reduxStore, children }: Props): JSX.Element => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        configsPromise.finally(() => {
            setIsLoaded(true);
        });
    }, []);

    return (
        <ErrorBoundary>
            <ReactReduxProvider store={reduxStore}>
                <ReactQueryProvider queryClient={queryClient}>
                    <RecoilProvider>{isLoaded && children}</RecoilProvider>
                </ReactQueryProvider>
            </ReactReduxProvider>
        </ErrorBoundary>
    );
};

export default AppProvider;
