import { address } from '@waves/ts-lib-crypto';

import { SESSION_TTL } from '$shared/constants';

import authService from './instance';
import AuthDataSignStore from './AuthDataSignStore';

function delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

class AuthDataSignService {
    public static get authParams(): Record<string, unknown> {
        const sign = AuthDataSignStore.get();
        if (!sign) return {};

        return {
            t: sign.timestamp,
            s: sign.signature,
            k: sign.publicKey,
        };
    }

    public static isAuthDataSigned(): boolean {
        try {
            const userAddress: string = authService.getAccountAddress();
            const sign = AuthDataSignStore.get();
            if (!sign) return false;
            if (!sign.timestamp || !sign.signature || !sign.publicKey || sign.timestamp + SESSION_TTL < Date.now()) {
                AuthDataSignStore.remove();
                return false;
            }

            return address({ publicKey: sign.publicKey }) === userAddress;
        } catch (e) {
            return false;
        }
    }

    public static async sign(authType: string, publicKey: string): Promise<{ signature: string; timestamp: integer }> {
        try {
            const address = authService.getAccountAddress();
            await delay(1000); // wait for keeper
            return authService.signAuthData(authType, address).then((data) => {
                AuthDataSignStore.set({
                    ...data,
                    publicKey,
                });

                return data;
            });
        } catch (e) {
            return Promise.reject();
        }
    }
}

export default AuthDataSignService;
