import 'dayjs/locale/zh';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';

export const SHORT_DATE_MONTH = 'D MMMM';
export const SHORT_DATE_MONTH_YEAR = 'D MMMM, YYYY';
export const SHORT_DATE = 'D MMM, YY';
export const SHORT_DATE_TIME = 'MMM D. HH:mm';
export const DATE_TIME = 'YYYY-MM-DD hh:mm:ss';
export const DATE = 'YYYY-MM-DD';
export const TIME = 'HH:mm';
export const MONTH_YEAR = 'MMMM YYYY';
export const SHORT_DAY = 'D';
export const HUMAN_READABLE_DATE_TIME = 'DD.MM HH:mm';
export const DATE_EUROPE = 'DD.MM.YYYY';
