import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import Title from '$/components/common/Title';
import Tag from '$/components/common/Tag';

import st from './BuyCard.module.scss';

interface IProps {
    disabled?: boolean;
    buttonText: ReactNode;
    className?: string;
    img: string;
    title: ReactNode;
    text: ReactNode;
    tag?: ReactNode;
    onClick: () => void;
}

const BuyCard: FC<IProps> = (props) => {
    const { disabled, buttonText, className, img, title, tag, text, onClick } = props;

    return (
        <button disabled={disabled} type="button" className={cn(st.card, className)} onClick={onClick}>
            <img src={img} alt="" />

            <span className={st.content}>
                <span className={st.title}>
                    <Title as="span" size="h4">
                        {title}{' '}
                    </Title>
                    {tag && (
                        <Tag theme="yellow" size="small" className={st.tag}>
                            {tag}
                        </Tag>
                    )}
                </span>

                {text}
            </span>

            <span className={st.btn}>{buttonText}</span>
        </button>
    );
};

export default BuyCard;
