import { createElement, FC, ReactNode } from 'react';
import cn from 'classnames';

import st from './Text.module.scss';

interface IProps {
    block?: boolean;
    transparent?: boolean;
    as?: 'p' | 'span' | 'div' | 'li' | 'figcaption';
    align?: 'left' | 'center' | 'right';
    children?: ReactNode;
    className?: string;
    uppercase?: boolean;
    capitalize?: boolean;
    lineThrough?: boolean;
    id?: string;
    size?: 'default' | 'xs' | 'small' | 'medium' | 'large' | 'xl';
    color?:
        | 'white'
        | 'black'
        | 'grayWhite'
        | 'yellow'
        | 'darkYellow'
        | 'orange'
        | 'red'
        | 'lightRed'
        | 'green'
        | 'lightGreen'
        | 'lightBlue'
        | 'turquoise'
        | 'purpleRose'
        | 'blue'
        | 'pink'
        | 'darkPink'
        | 'magenta';
}

const Text: FC<IProps> = (props) => {
    const {
        align = '',
        block,
        transparent,
        children,
        as = 'span',
        size = '',
        className,
        color = '',
        uppercase,
        capitalize,
        lineThrough,
        ...rest
    } = props;

    return createElement(
        as,
        {
            className: cn(
                st.title,
                {
                    [st.transparent]: transparent,
                    [st.block]: block,
                    [st[align]]: align,
                    [st[size]]: size,
                    [st[color]]: color,
                    [st.uppercase]: uppercase,
                    [st.capitalize]: capitalize,
                    [st.lineThrough]: lineThrough,
                },
                className,
            ),
            ...rest,
        },
        children,
    );
};

export default Text;
