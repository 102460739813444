import { SyntheticEvent, ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import { RentalDuckStatus } from '$shared/types/marketplaces';
import { AnimalCalls, AnimalType } from '$shared/types/animals';

import { checkTooLittleToClaim } from '$/utils/ducks';

import { IDuckDetails } from '../../../../shared/types/ducks';

interface IGetButtonText {
    onSale?: boolean;
    onFarming?: boolean;
    onFarmingVegg?: boolean;
    inWars?: boolean;
    claim: number;
    veggClaim: number;
    buttonText?: ReactNode;
    t: TFunction;
    inRental?: boolean;
    inHunt?: boolean;
    status?: RentalDuckStatus;
}

export const getButtonText = (data: IGetButtonText): ReactNode => {
    const {
        onSale,
        inWars,
        claim,
        veggClaim,
        buttonText = '',
        t,
        inRental,
        inHunt,
        status = '',
        onFarmingVegg,
        onFarming,
    } = data;

    switch (true) {
        case Boolean(buttonText): {
            return buttonText;
        }

        case inRental: {
            return t('portfolio.cards.in_rental');
        }

        case status === RentalDuckStatus.RENTED: {
            return t('portfolio.cards.rented');
        }

        case onSale: {
            return t('portfolio.cards.on_sale');
        }

        case inWars: {
            return t('portfolio.cards.in_wars');
        }

        case onFarming: {
            if (checkTooLittleToClaim(claim)) {
                return t('portfolio.cards.on_farming');
            }

            return t('portfolio.cards.claim', {
                claim: claim.toFixed(4),
                asset: AnimalCalls[AnimalType.DUCKS].farmingAsset,
            });
        }

        case inHunt: {
            return t('portfolio.cards.in_hunt');
        }

        // TODO; do some maths in the backend to fix this!

        case onFarmingVegg: {
            if (checkTooLittleToClaim(claim || veggClaim)) {
                return t('portfolio.cards.on_farming_vegg');
            }

            return t('portfolio.cards.claim_vegg', { claim: (veggClaim / 1e8 || claim / 1e6).toFixed(4) });
        }

        default: {
            return t('portfolio.cards.stake');
        }
    }
};

interface IGetButtonDisabled {
    disabled?: boolean;
    onFarming?: boolean;
    onFarmingVegg?: boolean;
    withoutLink?: boolean;
    toClaim?: number;
    veggClaim?: number;
    onClick?: (...args: IDuckDetails[]) => void;
}

export const getButtonDisabled = (data: IGetButtonDisabled): boolean => {
    const { disabled, withoutLink, toClaim, veggClaim, onClick, onFarmingVegg, onFarming } = data;

    if (disabled || (withoutLink && !onClick)) {
        return true;
    }

    if (toClaim) {
        return Boolean(onFarming && parseFloat((Math.floor(toClaim) / 100).toFixed(4)) <= 0);
    }
    if (veggClaim) {
        return Boolean(onFarmingVegg && parseFloat((Math.floor(veggClaim) / 100).toFixed(4)) <= 0);
    }

    return false;
};

interface IGetOnClick {
    onFarming?: boolean;
    claim: number;
    veggClaim: number;
    onFarmingVegg?: boolean;
    onClick?: (event: SyntheticEvent) => void;
}

export const getOnClick = (data: IGetOnClick): ((event: SyntheticEvent) => void) | undefined => {
    const { claim, veggClaim, onClick, onFarmingVegg, onFarming } = data;

    if (onFarmingVegg || onFarming) {
        return checkTooLittleToClaim(claim || veggClaim) ? undefined : onClick;
    }

    return onClick;
};
