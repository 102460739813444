import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer';
import authReducer from './auth.reducer';
import warsReduces from './wars.reducer';

export default combineReducers({
    settings: settingsReducer,
    auth: authReducer,
    wars: warsReduces,
});
