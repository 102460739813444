import { atom, selector } from 'recoil';

import { RequireSignatureResult } from '$shared/types/auth';

export const userAddressAtom = atom<addressId | null>({
    key: 'user.address',
    default: null,
});

export const userAuthType = atom({
    key: 'user.authType',
    default: '',
});

export const userSignatureAtom = atom<string | null>({
    key: 'user.signature',
    default: null,
});

export const userSignatureTimestampAtom = atom<integer | null>({
    key: 'user.signature-timestamp',
    default: null,
});

export const userIsAuthSelector = selector({
    key: 'user.is-auth',
    get: ({ get }) => get(userAddressAtom) != null,
});

export const userSignatureRequestAtom = atom<{
    isRequesting: boolean;
    subscribers: Array<(data: RequireSignatureResult) => Promise<RequireSignatureResult>>;
}>({
    key: 'user.signature-request',
    default: {
        isRequesting: false,
        subscribers: [],
    },
});
