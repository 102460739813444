import { Children } from 'react';
import cn from 'classnames';

import FlipButton from './FlipButton';
import { useHorizontalScrollable } from './hooks';
import st from './NewsCardsContainer.module.scss';

interface Props {
    children: JSX.Element[];
    className?: string;
    isColumn: boolean;
}

const COUNT_TO_ENABLE_HORIZONTAL_SCROLL = 3;

const NewsCardsContainer = ({ children, className, isColumn }: Props): JSX.Element => {
    const cardsCount = Children.count(children);
    const isHorizontalScrollable = !isColumn && cardsCount >= COUNT_TO_ENABLE_HORIZONTAL_SCROLL;
    const { containerRef, centeredElement, scrollToPrevious, scrollToNext } = useHorizontalScrollable(
        cardsCount,
        isHorizontalScrollable,
    );

    return (
        <div className={st.container}>
            {isHorizontalScrollable && (
                <FlipButton direction="previous" onClick={scrollToPrevious} isVisible={centeredElement > 0} />
            )}

            <div
                ref={containerRef}
                className={cn(
                    st.list,
                    {
                        [st.alignCenter]: cardsCount < COUNT_TO_ENABLE_HORIZONTAL_SCROLL,
                        [st.scrollable]: isHorizontalScrollable,
                        [st.isColumn]: isColumn,
                    },
                    className,
                )}
            >
                {children}
            </div>

            {isHorizontalScrollable && (
                <FlipButton direction="next" onClick={scrollToNext} isVisible={centeredElement < cardsCount - 1} />
            )}
        </div>
    );
};

export default NewsCardsContainer;
