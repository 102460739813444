import type { Middleware } from 'redux';

import { authService } from '$/services/authentication';
import { apiService } from '$/services/apiService';
import { removePublicKey, setPublicKey } from '$/browser/localStorage/public-key';

import {
    CHANGE_USER_PUBLIC_KEY,
    FINISH_LOGIN,
    KEEPER_LOGIN,
    LOGOUT,
    MM_LOGIN,
    MOBILE_KEEPER_LOGIN,
    UPDATE_BALANCES,
    WX_EMAIL_LOGIN,
    WX_WEB_LOGIN,
} from '../actions/actions';

/**
 When a setting value is changed, detect its value and add/remove
 a classname related with that setting from the target element.
 Export this method to talk directly with the middleware
 */
/*
    Hook into setting changes in order to change layout.
*/

const auth: Middleware = (store) => (next) => (action) => {
    const result = next(action);

    switch (action.type) {
        case WX_EMAIL_LOGIN: {
            removePublicKey();
            authService.startWxEmailLogin().then((authData) => {
                store.dispatch({
                    type: FINISH_LOGIN,
                    payload: {
                        type: WX_EMAIL_LOGIN,
                        ...authData,
                    },
                });
            });
            break;
        }
        case MM_LOGIN: {
            removePublicKey();
            authService.startMMLogin().then((authData) => {
                store.dispatch({
                    type: FINISH_LOGIN,
                    payload: {
                        type: MM_LOGIN,
                        ...authData,
                    },
                });
            });
            break;
        }
        case WX_WEB_LOGIN: {
            removePublicKey();
            authService
                .startWxWebLogin()
                .then((authData) => {
                    store.dispatch({
                        type: FINISH_LOGIN,
                        payload: {
                            type: WX_WEB_LOGIN,
                            ...authData,
                        },
                    });
                })
                .catch((e) => {
                    // eslint-disable-next-line no-alert
                    alert(
                        `Looks like your device does not support current authorization method. Please use email authorization. Debug: ${e?.message?.toString()}`,
                    );
                    window.location.href = '/login';
                });
            break;
        }
        case KEEPER_LOGIN: {
            removePublicKey();
            authService.startKeeperLogin().then((authData) => {
                store.dispatch({
                    type: FINISH_LOGIN,
                    payload: {
                        type: KEEPER_LOGIN,
                        ...authData,
                    },
                });
            });
            break;
        }
        case MOBILE_KEEPER_LOGIN: {
            removePublicKey();
            authService.startMobileKeeperLogin().then((authData) => {
                store.dispatch({
                    type: FINISH_LOGIN,
                    payload: {
                        type: MOBILE_KEEPER_LOGIN,
                        ...authData,
                    },
                });
            });
            break;
        }
        case CHANGE_USER_PUBLIC_KEY: {
            setPublicKey(action.publicKey);
            break;
        }
        case LOGOUT: {
            removePublicKey();
            authService.logout(action.authType);
            break;
        }
        case UPDATE_BALANCES: {
            if (action.address) {
                apiService.getBalances(action.address).then((res) => {
                    store.dispatch({
                        type: UPDATE_BALANCES,
                        payload: res,
                    });
                });
            }
            break;
        }
        case FINISH_LOGIN: {
            const qs = new URLSearchParams(window.location.search.slice(1));
            window.location.href = qs.get('returnUrl') || '/portfolio';
            break;
        }
    }
    return result;
};

export default auth;
