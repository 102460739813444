import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Title from '$/components/common/Title';

import { Card } from '../Card';
import st from './LootBox.module.scss';

interface IProps {
    onClick?: () => void;
}

const LootBox: FC<IProps> = ({ onClick }) => {
    const { t } = useTranslation();
    const [countdown, setCountdown] = useState('');
    const [canBeOpened, setCanBeOpened] = useState(false);

    useEffect(() => {
        let duration = new Date('2021-12-16T12:00:00.000Z').getTime() - Date.now();

        if (duration < 1) {
            duration = 0;
            setCanBeOpened(true);
        }

        const intervalId = window.setInterval(() => {
            if (duration < 1) {
                duration = 0;
                setCanBeOpened(true);
            }

            const hours = parseInt(String(dayjs.duration(duration).asHours()), 10);

            setCountdown(`${hours}:${dayjs.duration(duration).format('mm:ss')}`);

            duration -= 1000;
        }, 1000);

        return () => {
            window.clearInterval(intervalId);
        };
    }, []);

    return (
        <Card
            background="#9868ff"
            cover={<img src="/ducks/loot_box.svg" alt={t('lootbox.closed')} />}
            footer={{
                disabled: !canBeOpened,
                text: canBeOpened ? t('lootbox.open') : t('lootbox.open_in', { countdown }),
            }}
            onClick={onClick}
        >
            <Title as="h3" size="h2" className={st.title}>
                {t('lootbox.title')}
            </Title>

            <p className={st.text}>{t('lootbox.text')}</p>
        </Card>
    );
};

export default LootBox;
