export enum Worlds {
    None = 'None',
    Wars = 'wars',
    Hunt = 'hunt',
}

export enum PrizeEnum {
    GENESIS = 'GENESIS',
    '20EGG' = '20EGG',
    OBSTI = 'OBSTI',
    JU = 'JU',
    '30EGG' = '30EGG',
    CUSTOM = 'CUSTOM',
}
