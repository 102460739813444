import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import Swal from '$/components/Swal';
import Title from '$/components/common/Title';

import { Card } from '../Card';
import st from './HatchingTurtleCard.module.scss';

interface IProps {
    secondsRemaining: number;
    turtleClaimedSwalOptions?;
    className?: string;
    imgClassName?: string;
}

const HatchingTurtleCard: FC<IProps> = ({ secondsRemaining, turtleClaimedSwalOptions, className, imgClassName }) => (
    <>
        <Card
            className={cn(st.card, className)}
            coverClassName={cn(st.img, imgClassName)}
            cover={
                <Title as="span">
                    {Math.floor(secondsRemaining / 60)}:{String(secondsRemaining % 60).padStart(2, '0')}
                </Title>
            }
            footer={{
                disabled: true,
                text: <Trans i18nKey="turtle_incubator.claim.claim_btn_in_progress" />,
                className: st.footer,
                buttonClassName: st.btn,
            }}
        />

        {turtleClaimedSwalOptions && <Swal options={turtleClaimedSwalOptions} />}
    </>
);

export default HatchingTurtleCard;
