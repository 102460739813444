import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DUCK_COLORS } from '$shared/constants';

import { getPerchUrl, getVPerchUrl } from '$/utils/cards';
import Title from '$/components/common/Title';

import { Card } from '../Card';
import st from '../Artefact/Artefact.module.scss';

interface IProps {
    hideFooter?: boolean;
    color: string;
    onClick?: () => void;
    num?: number;
    isVPerch?: boolean;
}

const EmptyPerch: FC<IProps> = ({ hideFooter, color, onClick, num, isVPerch }) => {
    const { t } = useTranslation();
    return isVPerch ? (
        <Card
            background={`#${DUCK_COLORS[color]}`}
            cover={<img src={getVPerchUrl()} alt={t('vperch.select_duck_alt')} />}
            footer={{ hidden: hideFooter, text: t('vperch.select_duck') }}
            onClick={onClick}
        >
            <Title as="h3" size="h2" className={st.titleContainer}>
                {t('vperch.vperch')}
                {num !== undefined && num > 1 && <p className={st.number}>{num}x</p>}
            </Title>

            <p className={st.text}>{t('vperch.put_duck')}</p>
        </Card>
    ) : (
        <Card
            background={`#${DUCK_COLORS[color]}`}
            cover={<img src={getPerchUrl(color)} alt={t('perch.select_duck_alt')} />}
            footer={{ text: t('perch.select_duck') }}
            onClick={onClick}
        >
            <Title as="h3" size="h2" className={st.titleContainer}>
                {t('perch.perch')}
                {num !== undefined && num > 1 && <p className={st.number}>{num}x</p>}
            </Title>

            <p className={st.text}>{t('perch.put_duck')}</p>
        </Card>
    );
};

export default EmptyPerch;
