import { isDevEnv } from '$shared/utils';

const TEST = 'waves_ducks_test';
const JUNE_FIRST_NEVER_SHOW = 'waves-ducks:modals:june-1st-never-show';

// eslint-disable-next-line import/prefer-default-export
export const isTest = (): boolean => isDevEnv() || localStorage.getItem(TEST) != null;

export const setJuneFirstCharityModalAppearance = (flag: boolean): void =>
    localStorage.setItem(JUNE_FIRST_NEVER_SHOW, flag.toString());
export const canShowJuneFirstCharityModal = (): boolean => localStorage.getItem(JUNE_FIRST_NEVER_SHOW) !== 'true';
