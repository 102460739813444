/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEventHandler, FormEventHandler, KeyboardEventHandler, SyntheticEvent, useState } from 'react';

import useEffectiveCallback from './useEffectiveCallback';

export const useInputChangeCallback = (setter: (value: string) => void): ChangeEventHandler<HTMLInputElement> =>
    useEffectiveCallback(({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
        setter(value);
    });

export function useInputState(defaultValue: string): [string, ChangeEventHandler<HTMLInputElement>];
export function useInputState<S>(
    defaultValue: S,
    mapValue: (value: string) => S,
): [S, ChangeEventHandler<HTMLInputElement>];
export function useInputState<S>(
    defaultValue: S,
    mapValue: (value: string, setState: (value: S) => void) => void,
): [S, ChangeEventHandler<HTMLInputElement>];
export function useInputState<S>(
    defaultValue: S | string,
    mapValue: (value: string, setState: (value: S) => void) => any = (value) => value,
): [any, ChangeEventHandler<HTMLInputElement>] {
    const [state, setState] = useState(defaultValue);

    const changeHandler = useInputChangeCallback((value: string) => {
        if (mapValue.length === 1) {
            setState((mapValue as any)(value));
        } else {
            mapValue(value, setState);
        }
    });

    return [state, changeHandler];
}

export const useCheckboxState = (
    defaultValue: boolean,
    extraAction?: (newFlag: boolean) => void,
): [boolean, () => void] => {
    const [state, setState] = useState(defaultValue);

    const changeHandler = useEffectiveCallback(() =>
        setState((flag) => {
            const newFlag = !flag;
            extraAction?.(newFlag);
            return newFlag;
        }),
    );

    return [state, changeHandler];
};

export const useRangeInputState = (defaultValue: number): [number, FormEventHandler<HTMLInputElement>] =>
    useInputState(defaultValue, Number);

export const useSubmitOnEnterPress = (submitHandler: () => void): KeyboardEventHandler<HTMLInputElement> =>
    useEffectiveCallback((e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            submitHandler();
        }
    });
