import { ICollectiveFarmReward, ICollectiveFarms } from '$shared/types';
import { headersJson, ONE_HOUR } from '$shared/constants';
import CommonCollectiveFarmService from '$shared/services/collective-farm';
import { logError } from '$shared/services/log';
import { withCache } from '$shared/utils';

import helperService from '../helper';

class FrontendCollectiveFarmService extends CommonCollectiveFarmService {
    helperService = helperService;

    getCollectiveFarms = withCache(async (): Promise<ICollectiveFarms[]> => {
        const { http, API_URL } = this.helperService;

        return http
            .get(`${API_URL}/v1/collective-farms`, headersJson)
            .then(({ data }) => data)
            .catch((error) => {
                logError(error, '[ERROR][GET_COLLECTIVE_FARMS]');
                return [];
            });
    }, ONE_HOUR);

    getCollectiveFarmRewards = (address: string): Promise<ICollectiveFarmReward[]> => {
        const { http, API_URL } = this.helperService;

        return http
            .get(`${API_URL}/v1/collective-farms/rewards/${address}`, headersJson)
            .then(({ data }) => data)
            .catch((error) => {
                logError(error, '[ERROR][GET_COLLECTIVE_FARM_REWARDS]', address);
                return [];
            });
    };
}

export default FrontendCollectiveFarmService;
