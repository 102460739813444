import { FC, ReactNode, SyntheticEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { authAddressSelector } from '$/store/selectors';
import { TDuckCardFooter } from '$/types/duck';
import { CardFooter } from '$/components/Cards';

import st from './Card.module.scss';

interface IDuckCard {
    isPageWithNoParams?: boolean;
    isAuth?: boolean;
    background?: string;
    className?: string;
    secondSectionClassName?: string;
    cover?: ReactNode;
    coverClassName?: string;
    secondSection?: ReactNode;
    to?: string;
    footer?: TDuckCardFooter;
    footerEl?: ReactNode;
    onClick?: (event: SyntheticEvent) => void;
}

export const CardTemplate: FC<IDuckCard> = (props) => {
    const {
        isAuth,
        background,
        children,
        className,
        footer = {},
        footerEl,
        cover,
        coverClassName,
        secondSection,
        secondSectionClassName,
        to,
        onClick,
    } = props;

    const style = background ? { background } : undefined;

    const renderContent = () => {
        const content = (
            <>
                <div className={cn(st.imageContainer, coverClassName)}>{cover}</div>
                {children && <div className={st.content}>{children}</div>}
            </>
        );

        if (to) {
            return (
                <Link to={to} className={st.link}>
                    {content}
                </Link>
            );
        }

        return content;
    };

    return (
        <div className={cn(st.card, className)} style={style}>
            {renderContent()}

            {secondSection && <div className={cn(st.secondSection, secondSectionClassName)}>{secondSection}</div>}

            {footerEl ?? (
                <CardFooter
                    isAuth={isAuth}
                    to={to}
                    onClick={onClick}
                    {...footer}
                    className={cn(st.footer, footer?.className)}
                />
            )}
        </div>
    );
};

export const Card: FC<IDuckCard> = (props) => {
    const { isAuth, isPageWithNoParams } = props;
    const { address } = useParams<Record<string, string>>();
    const authAddress = useSelector(authAddressSelector);

    return <CardTemplate {...props} isAuth={isAuth || address === authAddress || isPageWithNoParams} />;
};
