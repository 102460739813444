import { toast } from 'react-toastify';

export const shortAddress = (address) =>
    `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`;

export const showErrorMessage = (_t, message, _e, options) => {
    console.error(message, _e);

    toast(message, {
        type: 'error',
        position: 'top-right',
        ...options,
    });
};

export const showSuccessMessage = (t, text, params, options) => {
    toast(t(text, params), {
        type: 'success',
        position: 'top-right',
        ...options,
    });
};

export const showInfoMessage = (t, text, params, options) => {
    toast(t(text, params), {
        type: 'info',
        position: 'top-right',
        ...options,
    });
};

/**
 * @deprecated
 */
export const debounce = (func, wait, immediate) => {
    let timeout;

    return function executedFunction(...args) {
        const context = this;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export const copyToClipboard = (text) => {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData('Text', text);
    }
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand('copy'); // Security exception may be thrown by some browsers.
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.warn('Copy to clipboard failed.', ex);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
};

export const shuffle = (array) => {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        // eslint-disable-next-line no-param-reassign
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const capitalizeString = (str) => str.charAt(0).toUpperCase() + str.slice(1); // or if you want lowercase the rest txt.slice(1).toLowerCase();

export const getQueryParameter = (query, paramName) => {
    if (!query) {
        return;
    }

    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');

        if (decodeURIComponent(pair[0]) === paramName) {
            return decodeURIComponent(pair[1]);
        }
    }
};

export const formatCurrency = (number) =>
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);

export const MAX_SUM = 100000000;
