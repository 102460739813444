import { useQuery, UseQueryResult } from 'react-query';

import type { IDuckDetails, WithHuntParams } from '$shared/types';
import { ONE_SECOND } from '$shared/constants';
import { WithRentalParams } from '$shared/types/hunt';

import ducksService from '$/services/api/ducks';
import { isAssetId } from '$/utils/assets';

export const duckDetailsQueryKey = (nftId: duckId): string[] => ['duck-details', nftId];
//** @deprecated */
const useDuckDetails = (nftId: string): UseQueryResult<IDuckDetails & WithHuntParams & WithRentalParams> =>
    useQuery(duckDetailsQueryKey(nftId), () => ducksService.fetchOneDuckDetails(nftId), {
        enabled: isAssetId(nftId),
        staleTime: 2 * ONE_SECOND,
    });

export default useDuckDetails;
