import { useRef, RefObject, useEffect } from 'react';

import { createObserver } from '$/browser/intersectionObserver';
import { useChangeUserAnnouncement } from '$/hooks/announcements';

interface UseObserve {
    ({ id, canObserve }): RefObject<HTMLElement>;
}

// eslint-disable-next-line import/prefer-default-export
export const useObserve: UseObserve = ({ id, canObserve }) => {
    const ref = useRef<HTMLElement>(null);
    const { mutate } = useChangeUserAnnouncement();

    useEffect(() => {
        let observer;
        const card = ref.current;

        if (card && canObserve) {
            const handleIntersect = (entries, obs) => {
                entries.forEach(({ isIntersecting }) => {
                    if (isIntersecting) {
                        mutate({ announcementKey: id, isViewed: true });
                        obs.unobserve(card);
                    }
                });
            };
            observer = createObserver({ options: { threshold: 0.2 }, handleIntersect });
            observer.observe(card);
        }

        return () => observer?.unobserve(card);
    }, [mutate, id, canObserve]);

    return ref;
};
