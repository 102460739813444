import { FC, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import { DUCK_GENERATION_NAMES } from '$shared/constants';

import type { TagTheme } from '$/types/ui/tags';
import ROUTES from '$/constants/routes';
import Title from '$/components/common/Title';
import CardTags from '$/components/Cards/CardTags';
import Tag from '$/components/common/Tag';
import { omitZeros } from '$/utils/formatNumber';
import useAuth from '$/hooks/useAuth';

import { Card } from '../Card';
import st from './Duckling.module.scss';

interface IProps {
    isPageWithNoParams?: boolean;
    duckling: {
        assetId: string;
        growth: number;
        feedNeeded: number;
        name: string;
        generation: string;
        givenName: string;
        owner: string;
        issuer: string;
        picture: string;
        blacklisted: boolean;
    };
}

const DucklingCard: FC<IProps> = (props) => {
    const {
        isPageWithNoParams,
        duckling: { assetId, growth, feedNeeded, generation, name, givenName, owner, picture, blacklisted },
    } = props;
    const id = assetId;
    const currentUser = useAuth().userAddress;

    const { t } = useTranslation();

    const tags = useMemo(() => {
        const res: Array<{ color: TagTheme; title: string }> = [];
        const { name } = DUCK_GENERATION_NAMES[generation ?? 'G'];

        res.push({ color: 'yellow', title: name });

        if (blacklisted) {
            res.push({ color: 'black', title: 'Blacklisted' });
        }

        return res;
    }, [generation, blacklisted]);

    const width = `${Math.min((growth / feedNeeded) * 100, 100)}%`;
    const fedEnough = growth >= feedNeeded;

    const secondSection = (
        <>
            <div className={st.row}>
                <div className={st.col}>
                    <p className={st.smallText}>{t('baby_duck.current_level')}</p>

                    <p className={st.value}>
                        {omitZeros(growth, 2)} / {omitZeros(feedNeeded, 2)}
                    </p>
                </div>

                <div className={st.col}>
                    {fedEnough && <p className={st.smallText}>{t('baby_duck.turn_into_duck')}</p>}
                </div>
            </div>

            <span className={st.line} style={{ width }} />
        </>
    );

    return (
        <Card
            className={st.duckling}
            cover={
                <>
                    <img src={picture} alt={name} />
                    <CardTags>
                        {tags.map(({ color, title }) => (
                            <Tag key={title} title={title} theme={color} size="small" />
                        ))}
                    </CardTags>
                </>
            }
            secondSection={secondSection}
            secondSectionClassName={st.secondSection}
            footer={{
                disabled: currentUser !== owner,
                text: t(fedEnough ? 'baby_duck.btn.turn' : 'baby_duck.btn.feed'),
            }}
            to={generatePath(ROUTES.DUCKLING, { nftId: id })}
            isPageWithNoParams={isPageWithNoParams}
        >
            <Title as="h3" size="h2">
                {givenName}
            </Title>
        </Card>
    );
};

export default DucklingCard;
