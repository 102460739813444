import type { ArtefactType } from './artefacts';
import type { BidStatusType } from './auctions';

export interface IMarketPrice {
    marketPrice: number;
    lastTrades: Array<{ amount: integer; timestamp: timestamp }>;
}

export interface ICachedMarketplaceBid {
    amount: number;
    author: string;
    status: string;
}

export interface ICachedMarketplaceAuction {
    auctionId: auctionId;
    amount?: number;
    assetId: string;
    highestBid: integer;
    bids?: Record<bidId, ICachedMarketplaceBid>;
    eggSell: boolean;
    description: string;
    instantPrice: integer;
    owner: string;
    startPrice: integer;
    startedAt: number;
    status: BidStatusType;
}

export interface IDuckStrategy {
    collection: boolean;
    buyback: number;
    duckWarsCards: string[];
    estBuyback: number;
    estReward: number;
    farming?: {
        userHasCurrentColorPerch: boolean;
        farmingPower: number;
        price: number;
        priceWithPerch: number;
    };
    duckHunt?: {
        eggsPerDay: number;
        total: string;
    };
}

export interface ICachedMarketplaceDuck {
    assetId: string;
    issueHeight: number;
    issueTimestamp: number;
    issuer: string;
    issuerPublicKey: string;
    name: string;
    description: string;
    decimals: number;
    reissuable: boolean;
    quantity: number;
    scripted: boolean;
    minSponsoredAssetFee: any;
    originTransactionId: string;
    canBreed: boolean;
    ducksWithSameGenes: number;
    rarity: number;
    globalFarmingPower: number;
    farmingPower: number;
    eggProduction: string;
    achievements: {
        a: string[];
        n: number;
    };
    auctionId: string;
    auction: ICachedMarketplaceAuction;
    strategy: IDuckStrategy;
    genotype: string;
    owner: string;
    rewardRatio: number;
    lockDuration: number;
    generation: string;
    status: RentalDuckStatus;
    rentedAt: Date;
    lockedAt: Date;
}

export interface ICachedMarketplaceTurtle {
    assetId: string;
    issueHeight: number;
    issueTimestamp: number;
    issuer: string;
    issuerPublicKey: string;
    name: string;
    description: string;
    decimals: number;
    reissuable: boolean;
    quantity: number;
    scripted: boolean;
    minSponsoredAssetFee: any;
    originTransactionId: string;
    canBreed: boolean;
    ducksWithSameGenes: number;
    rarity: number;
    globalFarmingPower: number;
    farmingPower: number;
    eggProduction: string;
    auctionId: string;
    auction: ICachedMarketplaceAuction;
    strategy: IDuckStrategy;
    genotype: string;
    owner: string;
    lockDuration: number;
    generation: string;
    status: RentalDuckStatus;
    lockedAt: Date;
}

export type ICachedMarketplaceAnimal = ICachedMarketplaceTurtle;

export interface ICachedMarketplaceItem {
    assetId: string;
    issueHeight: integer;
    issueTimestamp: number;
    issuer: string;
    issuerPublicKey: string;
    name: ArtefactType;
    description: string;
    decimals: integer;
    reissuable: boolean;
    quantity: integer;
    scripted: boolean;
    minSponsoredAssetFee: any;
    originTransactionId: string;
    type: string;
    id: string;
    owner: string;
    title: string;
    onSale: boolean;
    isArtefact: boolean;
    auctionId: string;
    auction: ICachedMarketplaceAuction;
}

export interface ICachedMarketplace {
    _id: string;
    ducks: ICachedMarketplaceDuck[];
    turtles: ICachedMarketplaceAnimal[];
    canines: ICachedMarketplaceAnimal[];
    felines: ICachedMarketplaceAnimal[];
    updatedAt: string;
    __v: number;
    items: ICachedMarketplaceItem[];
    petes: ICachedMarketplaceItem[];
}

export interface ICachedRentalMarketplace {
    ducks: ICachedMarketplaceDuck[];
    pagination: { page: number; pageSize: number; totalSize: number };
}

export interface ICachedRentalMarketplaceResponse {
    data: ICachedMarketplaceDuck[];
    pagination: { page: number; pageSize: number; totalSize: number };
}

export interface ICachedMyRentedDucksResponse {
    data: {
        data: ICachedMarketplaceDuck[];
        pagination: { page: number; pageSize: number; totalSize: number };
    };
}

export enum RentalplaceView {
    OFFERS = 'offers',
    MY_DUCKS = 'my_ducks',
}

export enum RentalDuckStatus {
    RENTED = 'RENTED',
    IDLE = 'IDLE',
}

export type RentalSCStaticKeys = {
    initialRentSlotsCount: number;
    minRewardPercentage: number;
    maxRewardPercentage: number;
    depositSteps: number;
};
