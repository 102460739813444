import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';

import { isDevEnv } from '$shared/utils';

import { setAcceptedTerms } from '$/browser/localStorage';
import { getAcceptedTerms } from '$/browser/localStorage/acceptedTerms';
import useWindowSize from '$/hooks/useWindowSize';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import Overlay from '$/components/Overlay';
import { NewsCard, NewsCardsContainer } from '$/components/Cards';
import Logo from '$/components/layout/Logo';
import Title from '$/components/common/Title';
import { notifierOpenAtom } from '$/components/layout/Notifier/state';
import { ROUTES } from '$/constants';
import { authAddressSelector } from '$/store/selectors';
import getUrl from '$/utils/getUrl';
import { useUnseenAnnouncements, useViewAllUserAnnouncements } from '$/hooks/announcements';

import { useOpenNews } from './hooks';
import { TERMS_ID } from './config';
import TermsCard from './TermsCard';
import st from './NewsModal.module.scss';

const NewsModal = (): JSX.Element => {
    const { t } = useTranslation();
    const [, setOpenNotifier] = useRecoilState(notifierOpenAtom);
    const address = useSelector(authAddressSelector);
    const [isOpen, setOpen] = useOpenNews();
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const isLoggedIn = Boolean(address);

    const { data: news = [] } = useUnseenAnnouncements();
    const { mutateAsync: viewAll } = useViewAllUserAnnouncements();

    const [isAgreeWithTerms, setAgreeWithTerms] = useState(isLoggedIn ? getAcceptedTerms().includes(TERMS_ID) : true);

    const handleTermsAgreeClick = useEffectiveCallback(() => {
        setAgreeWithTerms(true);
        setAcceptedTerms([TERMS_ID]);

        if (!news.length) {
            setOpen(false);
        }
    });

    const handleClose = useEffectiveCallback(() => {
        setOpen(false);
        viewAll({});
    });

    const handleSeeAllUpdates = useEffectiveCallback(() => {
        handleClose();

        setTimeout(() => setOpenNotifier(true), 500);
    });

    const renderCards = useEffectiveCallback(() => {
        const cardsData = isLoggedIn ? ['TERMS', ...news] : news;

        return cardsData.map((card) => {
            if (typeof card === 'string') {
                return (
                    <TermsCard
                        key={card}
                        isAgree={isAgreeWithTerms}
                        onClick={handleTermsAgreeClick}
                        isColumn={isMobile}
                    />
                );
            }

            return (
                <NewsCard
                    className={cn({ [st.newsDisabled]: !isAgreeWithTerms })}
                    canObserve={isAgreeWithTerms}
                    key={card.id}
                    isColumn={isMobile}
                    {...card}
                    link={{ ...card.link, disabled: !isAgreeWithTerms }}
                />
            );
        });
    });

    return (
        <Overlay containerClassName={st.overlay} className={st.content} isShown={isOpen} onClose={handleClose}>
            <div className={st.header}>
                <div className={st.topLine}>
                    <Logo
                        isMinimalistic
                        to={getUrl(ROUTES.FARM, { address })}
                        className={cn(st.logo, { [st.linkDisabled]: !isAgreeWithTerms })}
                        onClick={handleClose}
                    />

                    <div className={st.buttons}>
                        {isDevEnv() && (
                            <button
                                disabled={!isAgreeWithTerms}
                                type="button"
                                onClick={handleSeeAllUpdates}
                                className={cn(st.button, st.allUpdates)}
                            >
                                {t('news.allUpdates')}
                            </button>
                        )}

                        <button
                            disabled={!isAgreeWithTerms}
                            type="button"
                            onClick={handleClose}
                            className={cn(st.button, st.close)}
                        >
                            <img className={st.closeIcon} src="/img/icon-close-white.svg" alt="Close" />
                        </button>
                    </div>
                </div>

                <Title className={st.title}>{t('news.title')}</Title>
            </div>

            <div className={st.cardsWrapper}>
                <NewsCardsContainer isColumn={isMobile}>{renderCards()}</NewsCardsContainer>
            </div>
        </Overlay>
    );
};

export default NewsModal;
