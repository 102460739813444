import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Title from '$/components/common/Title';
import Button from '$/components/common/Button';
import Text from '$/components/common/Text';

import st from './MetaverseCard.module.scss';

interface Props {
    id: string;
    img: string;
    url: string;
    className: string;
    buttonType: 'play' | 'learn';
    disabled?: boolean;
    external?: boolean;
    isComingSoon?: boolean;
}

const getButtonText = (buttonType: 'play' | 'learn') => {
    if (buttonType === 'play') {
        return 'metaverse.btn.play';
    }
    return 'metaverse.btn.learn';
};

const MetaverseCard: FC<Props> = (props) => {
    const { buttonType, url, id, className, img, isComingSoon = false, disabled = false, external = false } = props;
    const { t } = useTranslation();
    const buttonText = getButtonText(buttonType);

    return (
        <Button
            fluid
            external={external}
            href={url}
            className={cn(st.card, st[id], className, { [st.disabled]: disabled })}
        >
            {isComingSoon && <div className={st.comingSoon}>Coming Soon</div>}

            <img className={st.img} src={img} alt={id} />

            <div className={st.content}>
                <Title as="p" size="h2" className={st.text}>
                    {t(`${id}.title`)}
                </Title>

                <Text as="p" className={st.text}>
                    {t(`${id}.description`)}
                </Text>
            </div>

            <Button fluid className={st.btn} disabled={disabled}>
                {t(buttonText)}
            </Button>
        </Button>
    );
};

export default MetaverseCard;
