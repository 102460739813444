import { useQuery, UseQueryResult } from 'react-query';

import { ONE_SECOND } from '$shared/constants';
import { WithRentalParams } from '$shared/types/hunt';

import ducksService from '$/services/api/ducks';
import { isAssetId } from '$/utils/assets';

export const duckHuntDetailsQueryKey = (nftId: duckId): string[] => ['duck-hunt-details', nftId];

const useDuckHuntDetails = (nftId: string): UseQueryResult<WithRentalParams> =>
    useQuery(duckHuntDetailsQueryKey(nftId), () => ducksService.fetchOneDuckHuntDetails(nftId), {
        enabled: isAssetId(nftId),
        staleTime: 2 * ONE_SECOND,
    });

export default useDuckHuntDetails;
