/* eslint-disable @typescript-eslint/no-explicit-any */
import CommonAnimalsBreedingService from '$shared/services/animals-breeding/CommonAnimalsBreedingService';
import { ANIMAL_PREFIXES } from '$shared/enums';
import { getAddress } from '$shared/domain/constants';
import { FELINE_GENERATION_NAMES } from '$shared/constants/felines';
import { AnimalBreedingDataMatch } from '$shared/types/animals';

import { DUXPLORER_URL } from '$/constants';

import helperService from '../helper';
import assetsService from '../assets';
import transactionService from '../transaction';

const FELINES_INCUBATOR_DAPP = getAddress('FELINES_INCUBATOR_DAPP');
const FELINES_BREEDER_DAPP = getAddress('FELINES_BREEDER_DAPP');

class FrontendFelineBreedingService extends CommonAnimalsBreedingService {
    GENERATION_NAMES = FELINE_GENERATION_NAMES;

    DAPP_ADDRESS = FELINES_BREEDER_DAPP;

    INCUBATOR_DAPP_ADDRESS = FELINES_INCUBATOR_DAPP;

    ANIMAL_PREFIXES = ANIMAL_PREFIXES.FELI;

    DUXPLORER_URL = DUXPLORER_URL;

    helperService = helperService;

    transactionService = transactionService;

    assetsService = assetsService;

    protected ANIMAL_BREEDING_DATA_MATCH = AnimalBreedingDataMatch.FELINE;
}

export default FrontendFelineBreedingService;
