import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import useLockBodyScroll from '$/hooks/useLockBodyScroll';
import Wrap from '$/components/common/Wrap';
import Title from '$/components/common/Title';
import Logo from '$/components/layout/Logo';

import st from './Overlay.module.scss';

interface IProps {
    isShown: boolean;
    hideCloseIcon?: boolean;
    className?: string;
    title?: ReactNode;
    titleClassName?: string;
    containerClassName?: string;
    titleContainerClassName?: string;
    onClose: () => void;
    showLogo?: boolean;
}

const Overlay: FC<IProps> = (props) => {
    const {
        isShown,
        hideCloseIcon,
        children,
        className,
        title,
        titleClassName,
        containerClassName,
        titleContainerClassName,
        onClose,
        showLogo,
    } = props;

    useLockBodyScroll(isShown);

    const renderOverlayBody = () => {
        if (!isShown) {
            return null;
        }

        return (
            <div className={cn(st.modal, containerClassName)}>
                {title && (
                    <Title as="div" className={cn(st.title, st.titleContainer, titleContainerClassName)}>
                        {showLogo && <Logo isMinimalistic className={st.logo} />}

                        <Wrap className={cn(st.title, titleClassName)}>{title}</Wrap>

                        {!hideCloseIcon && (
                            <button type="button" onClick={onClose} className={st.close}>
                                <img src="/img/icon-close-white.svg" alt="Close" />
                            </button>
                        )}
                    </Title>
                )}

                <Wrap className={cn(st.content, className)}>{children}</Wrap>
            </div>
        );
    };

    return createPortal(renderOverlayBody(), document.body);
};

export default Overlay;
