import CommonPricesBalanceService from '$shared/services/prices-and-balance';
import { EGG_DECIMALS } from '$shared/constants';
import { int } from '$shared/utils';

import { reachGoalOnce } from '$/utils/reachGoal';
import { API_URL, BACKEND_SWOPFI_URL, DUXPLORER_URL, ROUTING_SWOPFI_URL } from '$/constants';

import type { IBalances } from './types';
import helperService from '../helper';
import collectiveFarmService from '../collective-farm';
import artefactService from '../artefact';
import couponsService from '../coupons';
import accountBoosterService from '../account-boosters';
import incubatorService from '../incubator';

class FrontendPricesBalanceService extends CommonPricesBalanceService {
    protected helperService = helperService;

    protected collectiveFarmService = collectiveFarmService;

    protected artefactService = artefactService;

    protected accountBoosterService = accountBoosterService;

    protected incubatorService = incubatorService;

    protected couponsService = couponsService;

    protected BACKEND_SWOPFI_URL = BACKEND_SWOPFI_URL;

    protected ROUTING_SWOPFI_URL = ROUTING_SWOPFI_URL;

    protected DUXPLORER_URL = DUXPLORER_URL;

    protected API_URL = API_URL;

    MANTLE_PRICE: eggint = int(1e8);

    /**
     * Returns balancing eggs and waves
     * @param {string} address
     * @return Promise<number>
     */
    getBalances = async (address: string): Promise<IBalances> => {
        try {
            const [eggRes, wavesBalance] = await Promise.all([
                this.getEggsBalance(address),
                this.getWavesBalance(address),
            ]);
            if (eggRes > 0) reachGoalOnce('HAS_EGGS', eggRes.toString());
            if (wavesBalance > 0) reachGoalOnce('HAS_WAVES', wavesBalance.toString());
            return {
                eggsBalance: eggRes / EGG_DECIMALS,
                wavesBalance: +(wavesBalance / 1e8).toFixed(3),
            };
        } catch (e) {
            console.error('Error during balances update for', address, e);
            return {
                eggsBalance: 0,
                wavesBalance: 0,
            };
        }
    };
}

export default FrontendPricesBalanceService;
