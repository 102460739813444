import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ANIMAL_COLORS } from '$shared/constants';
import { isBirthdayEvent } from '$shared/domain/events';
import { AnimalColorsProps, AnimalType, IAnimalsDetails } from '$shared/types/animals';

import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import { useAnimalDetails, useAnimalParams } from '$/hooks/animals';
import getUrl from '$/utils/getUrl';
import ROUTES from '$/constants/routes';
import { getFPColor, getRarityColor } from '$/components/old-pages/Portfolio/misc';
import { EAnimalTabs, TAnimalCardFooter } from '$/types/animal';
import Title from '$/components/common/Title';
import AnimalTags from '$/components/animal/AnimalTags';
import { Props as ButtonProps } from '$/components/common/Button';
import { getToClaim } from '$/utils/ducks';

import { Card, CardTemplate } from '../Card';
import { getButtonDisabled, getButtonText, getOnClick } from './config';
import st from './Animal.module.scss';
import AnimalCoverContent from './AnimalCoverContent';

export interface IProps {
    isAuth?: boolean;
    disabled?: boolean;
    withoutGeneration?: boolean;
    withoutLink?: boolean;
    withoutAuth?: boolean;
    buttonText?: ReactNode;
    buttonSize?: ButtonProps['size'];
    overlay?: ReactNode;
    params: IAnimalsDetails;
    onClick?: (params: IAnimalsDetails) => Promise<void>;
    className?: string;
    to?: string;
    secondSection?: ReactNode;
    secondSectionClassName?: string;
    imageClassName?: string;
    footer?: TAnimalCardFooter;
    footerEl?: ReactNode;
    canBreed?: boolean;
    animal: AnimalType;
    issueNumber?: number;
}

const Animal: FC<IProps> = (props) => {
    const {
        isAuth,
        disabled,
        withoutGeneration,
        withoutLink,
        withoutAuth,
        buttonSize,
        buttonText,
        children,
        className,
        footer,
        footerEl,
        imageClassName,
        overlay,
        params,
        params: { assetId, canBreed, name, onFarming, onSale, toClaim, farmingParams, achievements, issueNumber },
        secondSection,
        secondSectionClassName,
        to,
        onClick,
        animal,
    } = props;

    const [cacheTs, setCacheTs] = useState(0);
    const { t } = useTranslation();

    const {
        bgColor,
        color = '',
        animalDetails,
        generationName,
        generationNumber,
    } = useAnimalParams(params, animal, { cacheTs }) || {};

    let fp = farmingParams?.farmingPower;
    if (!achievements || !fp) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const duckDetailsData = useAnimalDetails(assetId, animal);
        fp = duckDetailsData.data?.farmingParams?.farmingPower;
    }
    const animalProps = ANIMAL_COLORS.find(({ animalType }) => animalType === animal) as AnimalColorsProps;
    const href = to || getUrl(ROUTES[animal], { nftId: assetId, tab: EAnimalTabs.MAIN });
    const { name: title, image } = animalDetails || {};
    const animalDetailsResponse = useAnimalDetails(assetId, animal);
    const animalNumber = issueNumber ?? animalDetailsResponse.data?.issueNumber;
    const farmData = animalDetailsResponse.data?.farmingParams?.farmingPower;
    const tagsParams = {
        assetId,
        bgColor,
        animalNumber,
        generationName,
        generationNumber,
        name,
        farmingPower: fp || 0,
        farmingColor: getFPColor(farmData),
        withoutGeneration,
        canBreed,
        rarity: animalDetailsResponse.data?.rarity,
        rarityColor: getRarityColor(animalDetailsResponse.data?.rarity),
        stakedBefore: onFarming || farmingParams?.stakedBefore,
    };

    const claim = useMemo(() => {
        if (onFarming) {
            return Number((getToClaim(farmingParams?.toClaim || toClaim) / 1e6).toFixed(4));
        }
        return Number(((toClaim ?? 0) / 100).toFixed(4));
    }, [farmingParams?.toClaim, onFarming, toClaim]);
    const clickHandler = useEffectiveCallback(async () => {
        await onClick?.(params);
        setCacheTs(Date.now());
    });
    const Component = withoutAuth ? CardTemplate : Card;
    let bg = '';
    switch (true) {
        case isBirthdayEvent():
            bg = `center / cover url("/animals/birthday-bg/${color}.svg")`;
            break;
        default:
            bg = `#${animalProps.colors[color]}`;
            break;
    }

    const defaultFooter = {
        buttonSize,
        disabled: getButtonDisabled({ disabled, withoutLink, toClaim, onFarming, onClick }),
        text: getButtonText({
            animalType: animal,
            onSale,
            onFarming,
            claim,
            buttonText,
            t,
        }),
    };

    return (
        <Component
            className={className}
            secondSectionClassName={secondSectionClassName}
            isAuth={isAuth}
            background={bg}
            coverClassName={cn(imageClassName)}
            cover={
                <>
                    <AnimalCoverContent image={image} title={title} animal={animal} />
                    {overlay}
                </>
            }
            footerEl={footerEl}
            footer={footer || defaultFooter}
            {...{
                secondSection,
            }}
            {...(!withoutLink && { to: href })}
            {...(onClick && { onClick: getOnClick({ onFarming, claim, onClick: clickHandler }) })}
        >
            <Title as="h3" size="h2" className={st.title}>
                {title}
            </Title>
            <AnimalTags data={tagsParams} className={st.tags} />
            {children}
        </Component>
    );
};

export default Animal;
