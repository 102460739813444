import { configureStore, Store } from '@reduxjs/toolkit';

import { isTest } from '$/browser/localStorage';

import reducers from './reducers/reducers';
import settingsMiddleware from './middlewares/settings.middleware';
import authMiddleware from './middlewares/auth.middleware';
import warsMiddleware from './middlewares/wars.middleware';
import { persistedState, saveState } from './persisted.store';

function configureAppStore(): Store {
    const store = configureStore({
        devTools: isTest(),
        preloadedState: persistedState,
        reducer: reducers,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: isTest(),
                serializableCheck: isTest(),
            })
                .concat(settingsMiddleware)
                .concat(authMiddleware)
                .concat(warsMiddleware),
    });

    store.subscribe(() => {
        saveState({
            ...store.getState(),
            buyEggs: {
                depositLimit: { min: null, max: null },
            },
        });
    });
    return store;
}

export default configureAppStore;
