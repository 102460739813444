import React, { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import { ANIMAL_GENERATION_NAMES } from '$shared/constants';

import { IProps as TagProps } from '$/components/common/Tag';
import { TTagsAnimalData } from '$/types/ui/tags';
import { getRarityName } from '$/components/old-pages/Portfolio/misc';

const isBreedLater = (generationName) => {
    const sortedAnimal = Object.keys(ANIMAL_GENERATION_NAMES).sort();
    const { name } = ANIMAL_GENERATION_NAMES[sortedAnimal[sortedAnimal.length - 1]];
    return generationName === name;
};

interface IGetTags extends TTagsAnimalData {
    t: TFunction;
}

type TGetTags = {
    isRender: boolean;
    content: ReactNode;
    id: string;
    mod?: string;
    theme?: TagProps['theme'];
    tooltipText?: string;
    stakedBefore?: boolean;
};

const getTags = (params: IGetTags): TGetTags[] => {
    const {
        assetId,
        bgColor = '',
        canBreed = true,
        animalNumber,
        generationName,
        generationNumber,
        name,
        farmingPower,
        farmingColor,
        t,
        withoutGeneration,
        stakedBefore,
        rarity,
        rarityColor,
    } = params;

    const prefixToReplace = name.split('-')[0];

    return [
        {
            isRender: Boolean(animalNumber),
            content: `#${animalNumber}`,
            id: `num_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: true,
            content: name.replace(`${prefixToReplace}-`, ''),
            id: `genotype_${assetId}`,
            theme: 'genotype',
            tooltipText: t('animal.genotype'),
        },
        {
            isRender: !withoutGeneration,
            content: generationName,
            id: `generation_${assetId}`,
            mod: bgColor,
            tooltipText: t('common.generation_tooltip', { number: generationNumber }),
        },
        {
            isRender: !stakedBefore,
            content: (
                <>
                    {t('duck.farmPower')}
                    <strong>~{farmingPower?.toFixed(0)}</strong>
                </>
            ),
            id: `fp_${assetId}`,
            theme: 'genotype',
        },
        {
            isRender: stakedBefore === true,
            content: (
                <>
                    {t('animal.farm_power')}
                    <strong> {farmingPower?.toFixed(0)}</strong>
                </>
            ),
            id: `stakedBefore_${assetId}`,
            mod: farmingColor,
            tooltipText: t('animal.farm_power'),
        },
        {
            isRender: rarity !== undefined,
            content: (
                <>
                    {t('duck.rarity')}
                    <strong>{rarity?.toFixed(0)}%</strong>
                </>
            ),
            id: `rarity_${assetId}`,
            mod: rarityColor,
            tooltipText: getRarityName(rarity, t),
        },
        {
            isRender: isBreedLater(generationName),
            content: t('common.breed_later'),
            id: `breed_later_${assetId}`,
            theme: 'green',
        },
        {
            isRender: canBreed,
            content: t('common.can_breed'),
            id: `can_breed_${assetId}`,
            theme: 'green',
        },
        {
            isRender: !canBreed,
            content: t('common.sterile'),
            id: `sterile_${assetId}`,
            theme: 'red',
        },
    ];
};

export default getTags;
