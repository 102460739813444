import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IArtefact } from '$shared/types';

import useStakeableItemHandler from '$/hooks/items/useStakeableItemHandler';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';

import AbstractArtefact from './AbstractArtefact';

interface Props {
    artefact: IArtefact;
    onUse?: (artefact: IArtefact) => Promise<void>;
    num?: number;
}

const StakableArtefact = ({ artefact, onUse, num }: Props): JSX.Element => {
    const { t } = useTranslation();
    const toggleStake = useStakeableItemHandler(artefact);
    const clickHandler = useEffectiveCallback(async () => {
        await toggleStake();
        await onUse?.(artefact);
    });

    if (artefact.type === 'ART-XSOCK') {
        return (
            <AbstractArtefact
                artefact={artefact}
                footerButtonText={artefact.isStaked ? t('artefact.is_active') : t('artefact.activate')}
                onClick={artefact.isStaked ? undefined : clickHandler}
                num={num}
            />
        );
    }

    return (
        <AbstractArtefact
            artefact={artefact}
            footerButtonText={artefact.isStaked ? t('artefact.deactivate') : t('artefact.activate')}
            onClick={clickHandler}
            num={num}
        />
    );
};

export default StakableArtefact;
