import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IArtefact } from '$shared/types';

import AbstractArtefact from './AbstractArtefact';

interface Props {
    artefact: IArtefact;
    num?: number;
}

const ArtefactInCooldown = ({ artefact, num }: Props): JSX.Element => {
    const { cooldown } = artefact;
    const { t } = useTranslation();

    return <AbstractArtefact num={num} artefact={artefact} footerButtonText={t('artefact.cooldown', { cooldown })} />;
};

export default ArtefactInCooldown;
