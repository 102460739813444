import { useEffect, useRef } from 'react';

function useEventListener<T>(
    eventName: string,
    handler: (event: T) => void,
    element?: HTMLElement | Window | Document,
): void {
    const savedHandler = useRef<((event: T) => void) | null>(null);

    let el = element;

    if (!el) {
        el = window;
    }

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = el && el.addEventListener;

        if (!isSupported) {
            return;
        }

        const eventListener = (event: Event) => {
            if (savedHandler.current) {
                savedHandler.current(event as unknown as T);
            }
        };

        el?.addEventListener(eventName, eventListener);

        return () => {
            el?.removeEventListener(eventName, eventListener);
        };
    }, [eventName, el]);
}

export default useEventListener;
