import { lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import ROUTES from '$/constants/routes';
import PageLoader from '$/components/common/PageLoader';

const LoginRoutes = lazy(() => import('./routes/LoginRoutes'));
const DuckWarsRoutes = lazy(() => import('./routes/DuckWarsRoutes'));
const AppRoutes = lazy(() => import('./routes/AppRoutes'));
const DuckHuntRoutes = lazy(() => import('./routes/DuckHuntRoutes'));
const LandingRoutes = lazy(() => import('./routes/LandingRoutes'));
const EventsRouter = lazy(() => import('./routes/EventsRouter'));
const OtherUserViewerPage = lazy(() => import('./pages/OtherUserViewerPage'));
const ReferralPage = lazy(() => import('./pages/ReferralPage'));
const MainLandingPage = lazy(() => import('./pages/LandingMain'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));

const Routes = ({ location }) => (
    <Suspense fallback={<PageLoader />}>
        <Switch location={location}>
            <Route path={ROUTES.LOGIN_SEED} component={LoginRoutes} />
            <Route path={ROUTES.LOGIN} component={LoginRoutes} />

            <Route path="/w2/promo/fight/farms" component={DuckWarsRoutes} />
            <Route path="/w2/choose-collective-farm" component={DuckWarsRoutes} />

            <Route path={ROUTES.FIGHT} component={AppRoutes} />
            <Route path={ROUTES.CHOOSE_COLLECTIVE_FARM} component={AppRoutes} />
            <Route path={ROUTES.CHOOSE_DUCK} component={AppRoutes} />
            <Route path={ROUTES.VICTORY} component={AppRoutes} />
            <Route path={ROUTES.LOST} component={AppRoutes} />
            <Route path={ROUTES.NO_WINNER} component={AppRoutes} />
            <Route path={ROUTES.FARM} component={AppRoutes} />
            <Route path={ROUTES.DUCKLING} component={AppRoutes} />
            <Route path={ROUTES.REBIRTH} component={AppRoutes} />
            <Route path={ROUTES.REBIRTH_TURTLE} component={AppRoutes} />
            <Route path={ROUTES.REBIRTH_CANINE} component={AppRoutes} />
            <Route path={ROUTES.REBIRTH_FELINE} component={AppRoutes} />
            <Route path={ROUTES.SHAKEIT} component={AppRoutes} />
            <Route path={ROUTES.ITEM} component={AppRoutes} />
            <Route path={ROUTES.PETE} component={AppRoutes} />
            <Route path={ROUTES.BREEDING} component={AppRoutes} />
            <Route path={ROUTES.DUCK_OF_THE_YEAR_2021} component={AppRoutes} />
            <Route path={ROUTES.DUCK_OF_THE_YEAR_2022} component={AppRoutes} />
            <Route path={ROUTES.DUCK_OF_THE_YEAR} component={AppRoutes} />
            <Route path={ROUTES.SWAP_EGG} component={AppRoutes} />
            <Route path={ROUTES.OLD_MARKETPLACE} component={AppRoutes} />
            <Route path={ROUTES.MARKETPLACE} component={AppRoutes} />
            <Route path={ROUTES.COLLECTIVE_FARMS} component={AppRoutes} />
            <Route path={ROUTES.DUCK} component={AppRoutes} />
            <Route path={ROUTES.TURTLE} component={AppRoutes} />
            <Route path={ROUTES.CANINE} component={AppRoutes} />
            <Route path={ROUTES.FELINE} component={AppRoutes} />
            <Route path={ROUTES.GUIDE} component={AppRoutes} />
            <Route path={ROUTES.ONBOARDING} component={AppRoutes} />
            <Route path={ROUTES.HATCH_DUCK} component={AppRoutes} />
            <Route path={ROUTES.HATCH_TURTLE} component={AppRoutes} />
            <Route path={ROUTES.HATCH_CANINE} component={AppRoutes} />
            <Route path={ROUTES.HATCH_FELINE} component={AppRoutes} />
            <Route path={ROUTES.SETTINGS} component={AppRoutes} />
            <Route path={ROUTES.REFERRALS} component={AppRoutes} />
            <Route path={ROUTES.BALANCE} component={AppRoutes} />
            <Route path={ROUTES.HISTORY} component={AppRoutes} />
            <Route path={ROUTES.PLAY_AND_EARN_MODAL} component={AppRoutes} />
            <Route path={ROUTES.WELCOME} component={AppRoutes} />
            <Route path={ROUTES.BUY_EGG_EXCHANGES} component={AppRoutes} />
            <Route path={ROUTES.BUY_EGG} component={AppRoutes} />
            <Route path={ROUTES.RAFFLE} component={AppRoutes} />
            <Route path={ROUTES.POOL} component={AppRoutes} />
            <Route path={ROUTES.STATISTICS} component={AppRoutes} />
            <Route path={ROUTES.WARS_STATS_DETAILS} component={AppRoutes} />
            <Route path={ROUTES.WARS_STATS_PLAYERS_DETAILS} component={AppRoutes} />
            <Route path={ROUTES.BREEDING_CALCULATOR} component={AppRoutes} />
            <Route path={ROUTES.ITEM_CRAFTING} component={AppRoutes} />

            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />

            <Route path={ROUTES.ADMIN_HUNT} component={AppRoutes} />
            <Route path={ROUTES.ADMIN_WARS} component={AppRoutes} />
            <Route path={ROUTES.ADMIN_WARS_MATCHES} component={AppRoutes} />
            <Route path={ROUTES.ADMIN_WARS_ROUNDS} component={AppRoutes} />

            <Route path={ROUTES.HUNT} component={DuckHuntRoutes} />

            <Route path={ROUTES.PERMISSIONLESS} component={EventsRouter} />
            <Route path={ROUTES.EVENT} component={EventsRouter} />

            <Route path={ROUTES.AS} component={OtherUserViewerPage} />

            <Route path={ROUTES.LANDING} component={LandingRoutes} />
            <Route path={ROUTES.LANDING_HUNT_LAUNCH} component={DuckHuntRoutes} />

            <Route path="/:referral" component={ReferralPage} />

            <Route component={MainLandingPage} />
        </Switch>
    </Suspense>
);

export default withRouter(Routes);
