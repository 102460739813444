export const toFixed =
    (fractionDigits: number) =>
    (n: number | string): string => {
        const num = typeof n === 'string' ? parseFloat(n) : n;

        return num === 0 ? '0' : num.toFixed(fractionDigits);
    };

export const toFixed8 = toFixed(8);
export const toFixed4 = toFixed(4);

export const omitZeros = (n: number, maxDecimals = 8): number => {
    let value = 0;
    if (typeof n === 'number' && n !== 0) {
        value = parseFloat(n.toFixed(maxDecimals).replace(/0+$/, ''));
    }

    return value;
};

export const separateThousands = (number = 0): string => number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\xa0');

export const fixFloat = (number: number | integer, { maxDecimals = 0 }: { maxDecimals?: number } = {}): number =>
    Math.round(number * 10 ** maxDecimals) / 10 ** maxDecimals;

export const fixFloatRoundUp = (number: number | integer, { maxDecimals = 0 }: { maxDecimals?: number } = {}): number =>
    Math.ceil(number * 10 ** maxDecimals) / 10 ** maxDecimals;

export const formatPercent = (n: number): number => Math.min(Math.round(n * 100), 100);
