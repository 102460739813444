import { useQuery, UseQueryResult } from 'react-query';

import type { IDuckType, IDuckWarsStats } from '$shared/types/games';
import { ONE_MINUTE } from '$shared/constants';

import gameService from '$/services/api/game';

export const useDuckAtWarsStats = (assetId: string): UseQueryResult<IDuckWarsStats> =>
    useQuery(['duck-wars-stats', assetId], () => gameService.fetchDuckAtWarsStats(assetId), { staleTime: ONE_MINUTE });

export const useDuckAtWarsDetails = (assetId: string): UseQueryResult<IDuckType | null> =>
    useQuery(['duck-wars-details', assetId], () => gameService.fetchDuckAtWarsDetails(assetId), {
        staleTime: ONE_MINUTE,
    });
