import { useTranslation } from 'react-i18next';

import type { ArtefactType } from '$shared/types';

import useAuth from '$/hooks/useAuth';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import useInvalidateQueryCache from '$/hooks/useInvalidateQueryCache';
import { maxToFeedWithSignatureQueryKey } from '$/hooks/baby-ducks/useMaxToFeedWithSignature';
import { showErrorMessage, showSuccessMessage } from '$/services/utils';
import transactionService from '$/services/api/transaction';
import { authService } from '$/services/authentication';

import useOracleStaticValue from '../oracle/useOracleStaticValue';

interface Artefact {
    assetId: artefactId;
    type: ArtefactType;
}

interface HandlerParams<A extends Artefact> {
    onUse?: (artefact: A) => void;
}

const useFeedLimitHandler = <A extends Artefact>(artefact: A, params?: HandlerParams<A>): (() => void) => {
    const { assetId } = artefact;
    const { t } = useTranslation();
    const { authType, userAddress } = useAuth();
    const invalidateQueryCache = useInvalidateQueryCache(maxToFeedWithSignatureQueryKey(userAddress));
    const { data: extraFee } = useOracleStaticValue('extraFee');

    return useEffectiveCallback(async () => {
        try {
            const { waitForTransaction } = await transactionService.invokeAuthService(
                authService.oneTimeUseFeed,
                authType,
                assetId,
                extraFee,
            );
            await waitForTransaction();
            await invalidateQueryCache();
            await params?.onUse?.(artefact);
            showSuccessMessage(t, 'Used successfully!');
        } catch (e) {
            transactionService.handleException(e, ({ message }) => showErrorMessage(t, message, e));
        }
    });
};

const useUsableItemHandler = <A extends Artefact>(artefact: A, params?: HandlerParams<A>): (() => void) => {
    const { type } = artefact;
    const feedLimitHandler = useFeedLimitHandler(artefact, params);
    const artFeeds = [
        'ART-FEED5',
        'ART-FEED10',
        'ART-FEED15',
        'ART-FEED20',
        'ART-FEED25',
        'ART-FEED50',
        'ART-FEED100',
        'ART-FEED500',
    ];

    if (artFeeds.some((artFeed) => artFeed === type)) {
        return feedLimitHandler;
    }

    return () => void 0;
};

export default useUsableItemHandler;
