import { FC } from 'react';
import cn from 'classnames';

import { TSVG } from '$/types/svg';

import st from './Icons.module.scss';

interface IProps {
    className?: string;
    item: TSVG;
    size?: 'default' | 'xs' | 'small' | 'middle' | 'large';
}

const Icon: FC<IProps> = ({ className, item: { id, viewBox }, size = 'default' }) => (
    <svg className={cn(st.icon, st[size], className)} viewBox={viewBox} role="img">
        <use xlinkHref={`/sprite/sprite.svg#${id}`} />
    </svg>
);

export default Icon;
