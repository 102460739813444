import React, { FC } from 'react';
import cn from 'classnames';

import st from './Spinner.module.scss';

interface IProps {
    className?: string;
    theme?: 'white' | 'blue';
}

const Spinner: FC<IProps> = ({ className, theme = 'white' }) => (
    <div className={cn(st.spinner, [st[theme]], className)} />
);

export default Spinner;
