/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef } from 'react';
import { type ExtractRouteParams, generatePath } from 'react-router';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import type { ROUTES } from '$/constants';

import useEffectiveCallback from './useEffectiveCallback';

type Routes = (typeof ROUTES)[keyof typeof ROUTES];

type Stringify<Params> = {
    [K in keyof Params]: string;
};

export const useSearchParams = <T extends Record<string, string>>(): { [K in keyof T]: T[K] | null } => {
    const { search } = useLocation();
    const paramObj = useRef(new URLSearchParams(search));

    const invoke = useEffectiveCallback(
        <M extends keyof URLSearchParams>(methodName: M, ...args: Parameters<any>): ReturnType<any> =>
            (paramObj.current[methodName] as any)(...args),
    );

    return useMemo(
        () =>
            new Proxy({} as T, {
                get: (_, p) => invoke('get', p.toString()),
                has: (_, p) => invoke('has', p.toString()),
            }),
        [invoke],
    );
};

export const useSearchParam = (name: string): string | null => useSearchParams()[name];

export const useRouteParams = <Route extends Routes>(..._routes: Route[]): Stringify<ExtractRouteParams<Route>> =>
    useParams();

export function useGoTo<Route extends Routes>(
    route: Route,
): (params: ExtractRouteParams<Route>, options?: { replace: true }) => void;
export function useGoTo<Route extends Routes>(
    route: Route,
    params: ExtractRouteParams<Route>,
): (options?: { replace: true }) => void;
export function useGoTo<Route extends Routes>(
    route: Route,
    params?: any,
): (paramsOrOptions?: any, options?: { replace: true }) => void {
    const history = useHistory();

    return useEffectiveCallback((paramsOrOptions, options) => {
        const finalParams = params ?? paramsOrOptions;
        const finalOptions = params ? paramsOrOptions : options;

        if (finalOptions?.replace) {
            return history.replace(generatePath(route, finalParams));
        }
        return history.push(generatePath(route, finalParams));
    });
}
