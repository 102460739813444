import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { TagTheme } from '$/types/ui/tags';
import TooltipItem from '$/components/old-pages/Portfolio/TooltipItem';

import Text from '../Text';
import st from './Tag.module.scss';

export interface IProps {
    className?: string;
    id?: string;
    size?: 'small' | 'medium' | 'large';
    title?: string;
    tooltipText?: string;
    theme?: TagTheme;
}

const Tag: FC<IProps> = ({ children, title, className, id, size = 'small', theme = '', tooltipText }) => {
    const { t } = useTranslation();
    const tag = (
        <Text className={cn(st.tag, st[size], st[theme], className)} color="white" size="small">
            {title ? t(title) : children}
        </Text>
    );

    if (tooltipText) {
        return (
            <TooltipItem defaultOpen id={id} placement="top" content={tooltipText}>
                {tag}
            </TooltipItem>
        );
    }

    return tag;
};

export default Tag;
