import * as Sentry from '@sentry/browser';
import { init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import { isDevEnv } from '$shared/utils';

import consoleInterceptors from './console-interceptors';

const loggerService = {
    init: (): void => {
        if (isDevEnv()) {
            return;
        }
        const originalConsole = window.console;

        window.console = {
            ...originalConsole,
            log: (...args) => {
                consoleInterceptors.log?.forEach((interceptor) => {
                    interceptor(...args);
                });
                originalConsole.log(...args);
            },
        };

        init({
            dsn: 'https://c301959b81565c7d897a4c64091df4c8@o4506956638388224.ingest.us.sentry.io/4506956640485376',
            integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] })],
            tracesSampleRate: 0,
            debug: isDevEnv(),
            environment: process.env.NODE_ENV,
            attachStacktrace: true,
        });
    },
    captureException: (exception): string | void =>
        isDevEnv() ? console.error(exception) : Sentry.captureException(exception),
};

export default loggerService;
