import { FC, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import cn from 'classnames';

import icoClose from '$/assets/sprite/close.svg';
import useLockBodyScroll from '$/hooks/useLockBodyScroll';
import Icon from '$/components/common/Icon';

import st from './Modal.module.scss';

interface IProps {
    isShown: boolean;
    hideCloseButton?: boolean;
    className?: string;
    overlayClassName?: string;
    onClose: (event: MouseEvent) => void;
}

const Modal: FC<IProps> = ({ isShown, hideCloseButton, children, className, overlayClassName, onClose }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useOnclickOutside(onClose as any);

    useLockBodyScroll(isShown);

    const renderModalBody = () => {
        if (!isShown) {
            return null;
        }

        return (
            <div className={cn(st.overlay, overlayClassName)}>
                <div className={cn(st.content, className)} ref={ref}>
                    {onClose && !hideCloseButton && (
                        <button className={st.closeBtn} type="button" onClick={onClose}>
                            <Icon item={icoClose} size="xs" />
                        </button>
                    )}

                    {children}
                </div>
            </div>
        );
    };

    return createPortal(renderModalBody(), document.body);
};

export default Modal;
