import React, { ReactNode } from 'react';
import cn from 'classnames';

import classes from './Tags.module.css';

interface Props {
    stack?: boolean;
    className?: string;
    children: ReactNode | ReactNode[];
}

const Tags = ({ stack, className, children }: Props): JSX.Element => (
    <div className={cn(classes.tags, className, { [classes.stack]: stack })}>{children}</div>
);

export default Tags;
