import React from 'react';

import type { IArtefact } from '$shared/types';

import AbstractArtefact from './AbstractArtefact';

interface Props {
    artefact: IArtefact;
    num?: number;
}

const NoUseArtefact = ({ artefact, num }: Props): JSX.Element => <AbstractArtefact num={num} artefact={artefact} />;

export default NoUseArtefact;
