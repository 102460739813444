import { Dispatch, SetStateAction, useState } from 'react';
import useOnclickOutside, { Return } from 'react-cool-onclickoutside';

import { noop } from '$shared/utils';

import useEffectiveCallback from '$/hooks/useEffectiveCallback';

import useEventListener from './useEventListener';

type TToggleOpen = {
    isOpen: boolean;
    ref: Return;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export const useOnEscapePress = (onClose: () => void): void => {
    const handleKeyUp = useEffectiveCallback(({ key }: KeyboardEvent) => {
        if (key === 'Escape') {
            onClose();
        }
    });

    useEventListener('keyup', handleKeyUp);
};

export const useToggleOpen = (onToggle?: (val: boolean) => void): TToggleOpen => {
    const [isOpen, setOpen] = useState(false);

    const close = useEffectiveCallback(() => {
        setOpen(false);
        onToggle?.(false);
    });

    const handleSetOpen = useEffectiveCallback(() => {
        setOpen((flag) => !flag);
        onToggle?.(!isOpen);
    });

    useOnEscapePress(isOpen ? close : noop);

    const ref = useOnclickOutside(isOpen ? close : noop);

    return { isOpen, ref, setOpen: handleSetOpen };
};
