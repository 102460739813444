import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { ROUTES } from '$/constants';
import { getAcceptedTerms } from '$/browser/localStorage';
import { authAddressSelector } from '$/store/selectors';
import { useUnseenAnnouncements } from '$/hooks/announcements';

import { TERMS_ID } from './config';

const TIMEOUT = 5000;
const EXTERNAL_PAGES: string[] = [ROUTES.HOME, ROUTES.LOGIN, ROUTES.LOGIN_SEED, ROUTES.PERMISSIONLESS];

// eslint-disable-next-line import/prefer-default-export
export const useOpenNews = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const isLoggedIn = useSelector(authAddressSelector);
    const { data: unseenNews } = useUnseenAnnouncements();
    const hasUnseenNews = !!unseenNews?.length;
    const [isOpen, setOpen] = useState(false);
    const { pathname } = useLocation();
    const timerId = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const isInternalPage = !EXTERNAL_PAGES.includes(pathname);
        const hasUnacceptedTerms = !getAcceptedTerms().includes(TERMS_ID);
        const shouldOpen = isLoggedIn ? hasUnseenNews || hasUnacceptedTerms : hasUnseenNews;

        if (isInternalPage) {
            timerId.current = setTimeout(() => setOpen(shouldOpen), TIMEOUT);
        }

        return () => {
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    }, [isLoggedIn, pathname, hasUnseenNews]);

    return [isOpen, setOpen];
};
