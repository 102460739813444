/* eslint-disable */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import React, { FC, useState } from 'react';
import Slider from 'rc-slider';

import { int } from '$shared/utils';

import mantleService from '$/services/MantleService';
import { apiService } from '$/services/apiService';
import { authService } from '$/services/authentication';
import couponsService from '$/services/api/coupons';
import { showErrorMessage, showSuccessMessage } from '$/services/utils';

import { IWithTranslation } from '../../common.types';
import 'rc-slider/assets/index.css';

const SwalWithReact = withReactContent(Swal);

interface IProps extends IWithTranslation {
    currentLevel: number;
    userAddress: addressId;
    artefactId: string;
    authType?: string;
}

interface IBodyProps extends IProps {
    onChangeSliderValue: (val: any) => void;
}

const UpgradeMantleBody: FC<IBodyProps> = (props) => {
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [selectedLevelPrice, setSelectedLevelPrice] = useState(0);

    const setLevel = (val) => {
        if (val === selectedLevel) return;
        setSelectedLevel(val);
        const temp =
            Math.ceil(
                (mantleService.calculatePriceForLevel(val) - mantleService.calculatePriceForLevel(props.currentLevel)) *
                    100,
            ) / 100;
        setSelectedLevelPrice(temp);
        props.onChangeSliderValue(val);
    };

    if (!selectedLevel) setLevel(props.currentLevel + 1);

    return (
        <div>
            <div className="pl-2 pr-2 update-level-modal">
                Current level: {props.currentLevel}
                <br />
                New level: {selectedLevel}
                <Slider
                    min={props.currentLevel + 1}
                    step={1}
                    max={props.currentLevel + 99}
                    onChange={setLevel}
                    defaultValue={selectedLevel}
                />
                Selected level price: {selectedLevelPrice}
                <p className="muted">
                    Each mantle level increases the earnings of a duck by 100%, and a fighter using a duck by 10%.
                    <br />
                    You can remove the mantle from the duck, the level of the mantle will not disappear.
                </p>
            </div>
        </div>
    );
};

class UpdateLevelModal {
    props: IProps;

    state: {
        currentLevel: number;
        newLevel: number;
        toPay: integer;
    };

    constructor(props: IProps) {
        this.props = props;
        this.state = {
            currentLevel: props.currentLevel,
            newLevel: props.currentLevel + 1,
            toPay: int(0),
        };
    }

    setToPay(): void {
        const alreadyInvested = mantleService.calculatePriceForLevel(this.state.currentLevel);
        const haveToInvestTotal = mantleService.calculatePriceForLevel(this.state.newLevel);
        this.state.toPay = int(Math.trunc((Math.ceil((haveToInvestTotal - alreadyInvested) * 100) / 100) * 1e8));
    }

    fire(): Promise<unknown> {
        return SwalWithReact.fire({
            title: this.props.t(`artefacts.upgrade_level`),
            html: (
                <UpgradeMantleBody
                    onChangeSliderValue={(val) => {
                        this.state.newLevel = val;
                        this.setToPay();
                    }}
                    userAddress={this.props.userAddress}
                    artefactId={this.props.artefactId}
                    currentLevel={this.props.currentLevel}
                    t={this.props.t}
                />
            ),
            showCloseButton: true,
            confirmButtonText: this.props.t(`duckpage.upgrade_mantle`),
            showLoaderOnConfirm: true,
            customClass: {
                container: 'swal-modal-footer-blue-center',
                popup: 'pl-2 pr-2 pt-2 pb-4',
            },
        }).then(async ({ isConfirmed }) => {
            const availableCoupons = await couponsService.fetchAvailableCoupons(this.props.userAddress);
            const toPay = couponsService.calculateDiscountedPrice(this.state.toPay, availableCoupons);
            const spentCoupons = couponsService.calculateSpentCoupons(this.state.toPay, availableCoupons);

            if (!isConfirmed) return;
            return authService
                .upgradeArtefact(this.props.authType, this.props.artefactId, toPay, spentCoupons)
                .then((invoke) => apiService.waitForTx(invoke.id, 1000))
                .then(() => {
                    showSuccessMessage(this.props.t, 'artefacts.mantle_upgraded');
                })
                .catch((e) => {
                    showErrorMessage(this.props.t, e.message.toString(), e);
                });
        });
    }
}

export default UpdateLevelModal;
