import { useQuery, UseQueryResult } from 'react-query';

import { ONE_SECOND } from '$shared/constants';
import { AnimalType, IAnimalsDetails } from '$shared/types/animals';

import caninesService from '$/services/api/canines';
import felinesService from '$/services/api/felines';
import { isAssetId } from '$/utils/assets';

export const animalDetailsQueryKey = (nftId: animalId): string[] => ['animal-details', nftId];

const changeServiceTypeAnimal = (animal: AnimalType) => {
    switch (animal) {
        case AnimalType.CANINES:
            return caninesService;
        case AnimalType.FELINES:
            return felinesService;
        default:
            return felinesService;
    }
};

const useAnimalDetails = (nftId: string, animalType: AnimalType): UseQueryResult<IAnimalsDetails> => {
    const animalService = changeServiceTypeAnimal(animalType);
    return useQuery(animalDetailsQueryKey(nftId), () => animalService.fetchOneAnimalDetails(nftId), {
        enabled: isAssetId(nftId),
        staleTime: 2 * ONE_SECOND,
    });
};

export default useAnimalDetails;
