import CommonProductionService from '$shared/services/production';

import helperService from '../helper';
import breedingService from '../breeding';
import incubatorService from '../incubator';
import breedingTurtlesService from '../turtles-breeding';
import farmingTurtlesService from '../turtles-farming';
import incubatorTurtlesService from '../turtles-incubator';
import farmingService from '../farming';
import veggFarmingService from '../vegg-farming';
import canineBreedingService from '../canines-breeding';
import felineBreedingService from '../feline-breeding';
import felinesIncubatorService from '../felines-incubator';
import caninesIncubatorService from '../canines-incubator';

class FrontendProductionService extends CommonProductionService {
    helperService = helperService;

    breedingService = breedingService;

    farmingService = farmingService;

    incubatorService = incubatorService;

    breedingTurtlesService = breedingTurtlesService;

    farmingTurtlesService = farmingTurtlesService;

    incubatorTurtlesService = incubatorTurtlesService;

    // CANINE
    breedingCaninesService = canineBreedingService;

    incubatorCaninesService = caninesIncubatorService;

    // FELINE
    breedingFelineService = felineBreedingService;

    incubatorFelinesService = felinesIncubatorService;

    // VEGG
    protected veggFarmingService = veggFarmingService;
}

export default FrontendProductionService;
