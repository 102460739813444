import type { IInGameNumbers, IInGameNumbersData } from '$shared/types';
import CommonGameApiService from '$shared/services/game';

import { AuthDataSignService } from '$/services/authentication';
import { API_URL } from '$/constants';

import helperService from '../helper';

class FrontendGameService extends CommonGameApiService {
    helperService = helperService;

    API_URL = API_URL;

    changeCollectiveFarm = async (params: {
        authType: string;
        publicKey: string;
        duckId: string;
        farmContract: string;
    }): Promise<null | void | undefined | unknown> => {
        const { authType, publicKey, duckId, farmContract } = params;

        if (!AuthDataSignService.isAuthDataSigned()) {
            await AuthDataSignService.sign(authType, publicKey);
        }

        return this.helperService.http
            .put(
                `${this.API_PREFIX}/ducks/selectedFarm/${duckId}/${farmContract}`,
                {},
                { params: AuthDataSignService.authParams },
            )
            .then(({ data }) => data);
    };

    getInGameNumbersData = async (): Promise<IInGameNumbersData | null> => {
        const { data: inGameNumbersData } = await this.helperService.http.get(
            `${this.API_URL}/v1/analytics/in-game-numbers`,
        );

        return inGameNumbersData;
    };

    getInGameNumbers = async (): Promise<IInGameNumbers[]> => {
        const result: IInGameNumbers[] = [];
        const { data: inGameNumbers } = await this.helperService.http.get<IInGameNumbersData | null>(
            `${this.API_URL}/v1/analytics/in-game-numbers`,
        );

        if (inGameNumbers !== null) {
            result.push(
                {
                    title: '12500 Waves',
                    subTitle: '$245440',
                    description: 'The most\nexpensive duck',
                    imgSrc: './img/landingMain/most-expensive-duck.png',
                },
                {
                    title: `$${inGameNumbers.monthlySales}`,
                    description: 'Monthly sales\nvolume',
                },
                {
                    title: `$${inGameNumbers.EGGToUsdtRate}`,
                    description: 'EGG to\nUSD rate',
                },
                {
                    title: inGameNumbers.collectiveFarmsInvestors,
                    description: 'Total collective\nfarms investors',
                },
                {
                    title: inGameNumbers.pvpCount,
                    description: 'PVP fights\nmonthly',
                },
                {
                    title: inGameNumbers.playersCount,
                    description: 'Total number\nof players',
                },
            );
        }

        return result;
    };
}

export default FrontendGameService;
